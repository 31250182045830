import { cloneDeep } from 'lodash'

import { NEW_ID } from '../../constants'

import IdGenerator from '../../utils/IdGenerator'

import {
	ApiDataType,
	BasicQuestionType,
	ChoiceType,
	ChoicesQuestionType,
	EditorActionType,
	TranslationsType,
	isApiDataType,
	isBasicQuestionType,
	isChoicesQuestionType,
	isDeleteQuestionPayload,
	isEmptyDataSet,
	isQuestionPositionPayload,
	isSaveTagRulesPayload,
	isSurveyStartType,
	isToggleVisiblePayload,
	isUpdateQuestionPayload,
} from '../../types'

export const editorReducer = (
	draftState: ApiDataType | undefined,
	action: EditorActionType
) => {
	if (action.type === 'setData') {
		if (
			typeof action.payload === 'undefined' ||
			isEmptyDataSet(action.payload) ||
			isApiDataType(action.payload)
		)
			return action.payload
	}
	if (draftState?.surveyDefinition) {
		if (action.type === 'saveTagRules') {
			if (!isSaveTagRulesPayload(action.payload)) return

			const { tagRules } = action.payload

			draftState.surveyDefinition.postProcessingRules.tagRules = tagRules
		} else if (action.type === 'saveQuestion') {
			if (
				isChoicesQuestionType(action.payload) ||
				isBasicQuestionType(action.payload) ||
				isSurveyStartType(action.payload)
			) {
				const question = { ...action.payload, changed: true }

				draftState.surveyDefinition.questions[question.order] = question
			}
		} else if (
			action.type === 'copyQuestion' &&
			(isChoicesQuestionType(action.payload) ||
				isBasicQuestionType(action.payload))
		) {
			const oldQuestion = action.payload
			let newQuestion = cloneDeep(oldQuestion)

			const newTranslationKeys = {
				headerTextKey: `${IdGenerator()}${Date.now()}`,
				nicknameTextKey: `${IdGenerator()}${Date.now()}`,
				ingressTextKey: `${IdGenerator()}${Date.now()}`,
			}

			newQuestion = {
				...newQuestion,
				...newTranslationKeys,
				id: NEW_ID,
				changed: true,
				order: draftState.surveyDefinition.questions.length - 1,
			}

			if (!draftState.translations) return

			Object.entries(draftState.translations).forEach(
				([language, translations]) => {
					const keysToReplace: Array<keyof typeof newQuestion> = [
						'headerTextKey',
						'nicknameTextKey',
						'ingressTextKey',
					]

					keysToReplace.forEach((translationName) => {
						const newTranslationKey = newQuestion[translationName]
						const oldTranslationKey = oldQuestion[translationName]

						if (
							typeof oldTranslationKey === 'string' &&
							typeof newTranslationKey === 'string'
						) {
							const oldTranslation = translations[oldTranslationKey]

							if (oldTranslation) {
								translations[newTranslationKey] = {
									key: newTranslationKey,
									changed: true,
									text: oldTranslation.text + ' - COPY',
									timeStamp: Date.now(),
								}
							}
						}
					})
				}
			)

			if (isChoicesQuestionType(newQuestion)) {
				const updateChoiceTextKeys = (
					choices: Array<ChoiceType>
				): Array<ChoiceType> => {
					const newChoices = choices.map((oldChoice) => {
						const keysToReplace: Array<keyof typeof oldChoice> = [
							'headerTextKey',
							'nicknameTextKey',
							'ingressTextKey',
							'subQuestionHeaderTextKey',
							'subQuestionNicknameTextKey',
							'subQuestionIngressTextKey',
						]

						const newChoiceTranslationKeys = keysToReplace.reduce(
							(sum: { [key: string]: string }, keyToReplace) => {
								const textKey = oldChoice[keyToReplace]

								if (keyToReplace in oldChoice && textKey) {
									sum[keyToReplace] = `${IdGenerator()}${Date.now()}`
								}

								return sum
							},
							{}
						)

						const newChoice = {
							...oldChoice,
							...newChoiceTranslationKeys,
							id: NEW_ID,
							offset: 0,
						}

						if (draftState.translations) {
							Object.entries(draftState.translations).forEach(
								([language, translations]) => {
									keysToReplace.forEach((translationName) => {
										const newTranslationKey = newChoice[translationName]
										const oldTranslationKey = oldChoice[translationName]

										if (
											typeof oldTranslationKey === 'string' &&
											typeof newTranslationKey === 'string'
										) {
											const oldTranslation = translations[oldTranslationKey]

											if (oldTranslation) {
												translations[newTranslationKey] = {
													key: newTranslationKey,
													changed: true,
													text: oldTranslation.text + ' - COPY',
													timeStamp: Date.now(),
												}
											}
										}
									})
								}
							)
						}

						if (oldChoice.choices) {
							newChoice.choices = updateChoiceTextKeys(oldChoice.choices)
						}

						return newChoice
					})

					return newChoices
				}

				newQuestion.choices = updateChoiceTextKeys(newQuestion.choices)
			}

			draftState.surveyDefinition.questions.splice(
				newQuestion.order,
				0,
				newQuestion
			)

			draftState.surveyDefinition.questions.forEach((question, index) => {
				question.changed = true
				question.order = index
			})
		} else if (action.type === 'addQuestion') {
			if (typeof action.payload !== 'string' || !draftState.translations) return

			const questions = draftState.surveyDefinition.questions
			const translations = draftState.translations['en-US']
			const choicesVariant = action.payload
			const newTranslationKeys = {
				headerTextKey: `${IdGenerator()}${Date.now()}`,
				nicknameTextKey: `${IdGenerator()}${Date.now()}`,
				ingressTextKey: `${IdGenerator()}${Date.now()}`,
			}

			if (!translations) return

			const submitQuestion = questions.pop()

			if (submitQuestion) {
				// Should in reality never be false.
				submitQuestion.changed = true
				submitQuestion.order = questions.length + 1
			}

			const newQuestion = {
				id: NEW_ID,
				changed: true,
				order: questions.length,
				visible: true,
				headerTextKey: newTranslationKeys.headerTextKey,
				nicknameTextKey: newTranslationKeys.nicknameTextKey,
				ingressTextKey: newTranslationKeys.ingressTextKey,
				choicesVariant: choicesVariant,
			}

			if (newQuestion.choicesVariant === 'basic') {
				questions.push({
					...newQuestion,
					showBirthdayQuestion: true,
					showGenderQuestion: true,
					showHeightQuestion: true,
					showWeightQuestion: true,
					calculateBmi: false,
					bmiTagRanges: [],
					showOnlyYear: false,
				})
			} else {
				questions.push({
					...newQuestion,
					alignment: 'vertical',
					imageId: undefined,
					videoId: undefined,
					choices: [],
				})
			}

			submitQuestion && questions.push(submitQuestion)

			Object.values(newTranslationKeys).forEach((key) => {
				const newTranslation = {
					id: NEW_ID,
					key: key,
					changed: true,
					text: '',
					timeStamp: Date.now(),
				}

				translations[key] = newTranslation
			})
		} else if (action.type === 'updateQuestionType') {
			if (!isUpdateQuestionPayload(action.payload)) return

			const { questionIndex, newQuestionVariant } = action.payload
			const oldQuestion = draftState.surveyDefinition.questions[questionIndex]

			if (newQuestionVariant === 'basic') {
				const newQuestion: BasicQuestionType = {
					id: oldQuestion.id,
					changed: true,
					order: oldQuestion.order,
					visible: oldQuestion.visible,
					headerTextKey: oldQuestion.headerTextKey,
					nicknameTextKey: oldQuestion.nicknameTextKey,
					ingressTextKey: oldQuestion.ingressTextKey,
					choicesVariant: 'basic',
					showWeightQuestion: true,
					showHeightQuestion: true,
					showBirthdayQuestion: true,
					showGenderQuestion: true,
					calculateBmi: false,
					bmiTagRanges: [],
					showOnlyYear: false,
				}

				draftState.surveyDefinition.questions[questionIndex] = newQuestion
			} else {
				if (isChoicesQuestionType(oldQuestion)) {
					oldQuestion.choicesVariant = newQuestionVariant
					oldQuestion.changed = true
				} else if (isBasicQuestionType(oldQuestion)) {
					const newQuestion: ChoicesQuestionType = {
						id: oldQuestion.id,
						changed: true,
						order: oldQuestion.order,
						visible: oldQuestion.visible,
						headerTextKey: oldQuestion.headerTextKey,
						nicknameTextKey: oldQuestion.nicknameTextKey,
						ingressTextKey: oldQuestion.ingressTextKey,
						alignment: 'vertical',
						imageId: undefined,
						videoId: undefined,
						choicesVariant: newQuestionVariant,
						choices: [],
					}

					draftState.surveyDefinition.questions[questionIndex] = newQuestion
				}
			}
		} else if (action.type === 'deleteQuestion') {
			if (!isDeleteQuestionPayload(action.payload) || !draftState.translations)
				return

			const { questionIndex } = action.payload

			const questions = draftState.surveyDefinition.questions
			const questionToDelete = questions[questionIndex]
			const cultureToDeleteFrom = draftState.translations['en-US']

			if (!questionToDelete) return

			questions.splice(questionIndex, 1)
			questions.forEach((question, questionIndex) => {
				question.order = questionIndex
				question.changed = true
			})

			if (!cultureToDeleteFrom) return

			delete cultureToDeleteFrom?.[questionToDelete.headerTextKey]
			delete cultureToDeleteFrom?.[questionToDelete.ingressTextKey]
		} else if (action.type === 'updateQuestionPosition') {
			if (!isQuestionPositionPayload(action.payload)) return

			const { oldIndex, newIndex } = action.payload
			const questions = draftState.surveyDefinition.questions
			const questionToMove = questions.splice(oldIndex, 1)[0]

			questionToMove.changed = true
			questions.splice(newIndex, 0, questionToMove)
			questions.forEach((question, questionIndex) => {
				question.order = questionIndex
				question.changed = true
			})
		} else if (action.type === 'toggleVisibleQuestion') {
			if (!isToggleVisiblePayload(action.payload)) return

			const questionToToggle =
				draftState.surveyDefinition.questions[action.payload.questionIndex]

			questionToToggle.changed = true
			questionToToggle.visible = !questionToToggle?.visible
		} else if (action.type === 'saveTranslations') {
			if (!isTranslationsType(action.payload)) return
			draftState.translations = action.payload
		}
	}
}

const isTranslationsType = (
	variableToCheck: unknown
): variableToCheck is TranslationsType =>
	(variableToCheck as TranslationsType)['en-US'] !== undefined ||
	(variableToCheck as TranslationsType)['nb-NO'] !== undefined ||
	(variableToCheck as TranslationsType)['sv-SE'] !== undefined ||
	(variableToCheck as TranslationsType)['da-DK'] !== undefined ||
	(variableToCheck as TranslationsType)['fi-FI'] !== undefined

export default editorReducer
