import * as React from 'react'
import color from 'color'
import { css } from 'styled-components/macro'
import useFetch, { CachePolicies } from 'use-http'

import Alert, { AlertVariant } from '../../components/Alert'
import Media from '../../components/Media'

import { ReactComponent as ImageIcon } from '../../images/image-placeholder.svg'
import { ReactComponent as UploadIcon } from '../../images/upload.svg'

import {
	ChoiceType,
	ChoicesQuestionType,
	QuestionActionType,
	isChoiceType,
} from '../../types'
import TextInput from '../../components/TextInput'

enum ImageType {
	Survey = 12,
}

const ImageUploadFields: React.FC<ImageFieldsProps> = ({
	questionContext,
	parentChoiceIndex,
	choiceIndex,
	isLoggedIn,
}) => {
	const { state, questionDispatch } = questionContext
	const { request: fileRequest, response: fileResponse } = useFetch(
		`${process.env.REACT_APP_EXORLIVE_API_URL}`,
		{
			credentials: 'include',
			cachePolicy: CachePolicies.NO_CACHE,
		}
	)

	const handleFileUpload = async (event: React.FormEvent<HTMLInputElement>) => {
		const uploadedImage = event.currentTarget.files?.[0]

		if (uploadedImage) {
			const formData = new FormData()
			formData.append(uploadedImage.name, uploadedImage)
			formData.append('entityId', '0')
			formData.append('type', String(ImageType.Survey))

			await fileRequest.post('/osloapi/ImageUpload', formData)

			if (fileRequest.data) {
				const newImageId = fileRequest.data.Data

				if (isChoiceType(state)) {
					const choiceWithoutParentChoice =
						state?.offset >= 0 && typeof parentChoiceIndex === 'undefined'

					const choiceWithParentChoice =
						state?.offset >= 0 &&
						typeof parentChoiceIndex === 'number' &&
						parentChoiceIndex >= 0

					if (choiceWithoutParentChoice) {
						questionDispatch({
							type: 'updateChoice',
							payload: {
								imageId: parseInt(newImageId),
								...(typeof choiceIndex === 'number'
									? { choiceIndex: choiceIndex }
									: {}),
							},
						})
					} else if (choiceWithParentChoice) {
						questionDispatch({
							type: 'updateChoice',
							payload: {
								imageId: parseInt(newImageId),
								...(typeof choiceIndex === 'number'
									? { choiceIndex: choiceIndex }
									: {}),
								...(typeof parentChoiceIndex === 'number'
									? { parentChoiceIndex: parentChoiceIndex }
									: {}),
							},
						})
					}
				} else {
					questionDispatch({
						type: 'updateQuestion',
						payload: {
							imageId: parseInt(newImageId),
						},
					})
				}
			}
		}
	}

	const onImageIdChange = (newImageId: string) => {
		if (isChoiceType(state)) {
			const choiceWithoutParentChoice =
				state?.offset >= 0 && typeof parentChoiceIndex === 'undefined'

			const choiceWithParentChoice =
				state?.offset >= 0 &&
				typeof parentChoiceIndex === 'number' &&
				parentChoiceIndex >= 0

			if (choiceWithoutParentChoice) {
				questionDispatch({
					type: 'updateChoice',
					payload: {
						imageId: parseInt(newImageId),
						...(typeof choiceIndex === 'number'
							? { choiceIndex: choiceIndex }
							: {}),
					},
				})
			} else if (choiceWithParentChoice) {
				questionDispatch({
					type: 'updateChoice',
					payload: {
						imageId: parseInt(newImageId),
						...(typeof choiceIndex === 'number'
							? { choiceIndex: choiceIndex }
							: {}),
						...(typeof parentChoiceIndex === 'number'
							? { parentChoiceIndex: parentChoiceIndex }
							: {}),
					},
				})
			}
		} else {
			questionDispatch({
				type: 'updateQuestion',
				payload: {
					imageId: parseInt(newImageId),
				},
			})
		}
	}

	return (
		<React.Fragment>
			<div
				css={css`
					margin-top: 2rem;
				`}
			>
				{typeof choiceIndex === 'undefined' ? <h4>Image</h4> : null}
				{!isLoggedIn ? (
					<Alert variant={AlertVariant.warning}>
						<p>You need to be logged in to upload images to ExorLive.</p>
					</Alert>
				) : null}
				{typeof choiceIndex === 'undefined' ? (
					<p>
						To use an image with a survey you can either type in the image ID or
						upload one by clicking upload image. <br /> To remove an image set
						the ID to be 0.
					</p>
				) : null}
				<div
					css={css`
						display: flex;
						flex-direction: column;
						gap: 1rem;
						@media only screen and (min-width: 766px) {
							flex-direction: row;
						}
					`}
				>
					<div
						css={css`
							flex: 1;
						`}
					>
						<div
							css={css`
								display: flex;
								flex: 1;
								align-self: flex-start;
							`}
						>
							<TextInput
								id={`image-id_${state.headerTextKey}`}
								label='Image id'
								type='number'
								containerCss={css`
									margin: 0;
									flex-grow: 1;
								`}
								value={
									typeof state.imageId === 'number' && state?.imageId > -1
										? state.imageId
										: ''
								}
								onChange={(event) => onImageIdChange(event.currentTarget.value)}
							/>
							<span
								css={css`
									margin-inline: 1rem;
									margin-top: auto;
								`}
							>
								or
							</span>
							<div
								css={css`
									display: flex;
									flex-direction: column;
									align-self: flex-end;
								`}
							>
								<label
									css={[
										uploadButtonStyle,
										isLoggedIn
											? css`
													&:hover {
														background-color: #218838;
													}

													&:active {
														background-color: #1e7e34;
													}
											  `
											: css`
													opacity: 0.4;

													&:hover {
														cursor: default;
													}
											  `,
									]}
									htmlFor={`image-uploader_${state.headerTextKey}`}
								>
									<div
										css={css`
											user-select: none;
											display: flex;
											align-items: center;
											font-size: 0.9rem;
										`}
									>
										<UploadIcon
											css={css`
												fill: white;
											`}
										/>
										<span>Upload image</span>
									</div>
								</label>
								<input
									css={css`
										display: none;
									`}
									disabled={!isLoggedIn}
									id={`image-uploader_${state.headerTextKey}`}
									type='file'
									accept='image/*'
									onChange={handleFileUpload}
								/>
							</div>
						</div>
						{fileRequest.error ? (
							<Alert
								variant={AlertVariant.error}
								containerCss={css`
									margin-top: 1rem;
								`}
							>
								<p>{fileResponse.status}: Image upload failed</p>
							</Alert>
						) : null}
					</div>
					<div
						css={css`
							flex: 1;
						`}
					>
						<p
							css={css`
								font-size: 0.9rem;
								margin: 0;
							`}
						>
							Image preview
						</p>
						{state.imageId ? (
							<Media
								imageId={state.imageId}
								containerCss={css`
									border: 2px solid #afb2b7;
									min-height: 200px;
									border-radius: 2px;
								`}
							/>
						) : (
							<div
								css={css`
									display: flex;
									align-items: center;
									justify-content: center;
									min-height: 200px;
									width: 100%;
									padding: 1rem 0;
									border: 2px dashed #afb2b7;
									border-radius: 2px;

									> svg {
										height: 40px;
										width: 40px;
										fill: #afb2b7;
									}
								`}
							>
								<ImageIcon />
							</div>
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

type ImageFieldsProps = {
	questionContext: {
		state: ChoicesQuestionType | ChoiceType
		questionDispatch: React.Dispatch<QuestionActionType>
	}
	parentChoiceIndex?: number
	choiceIndex?: number
	isLoggedIn: boolean
}

const uploadButtonStyle = css`
	display: flex;
	background-color: #28a745;
	color: white;
	border: 1px solid ${color('#28a745').darken(0.2).hex()};
	border-radius: 2px;
	padding: 0.25rem 0.5rem;
	cursor: pointer;
`

export default ImageUploadFields
