import * as React from 'react'
import { useImmerReducer } from 'use-immer'

import editorReducer from './reducers/editorReducer'

import { ApiDataType, EditorDispatchType } from '../types'

const EditorStateContext = React.createContext<ApiDataType | undefined>(
	undefined
)
const EditorDispatchContext = React.createContext<
	EditorDispatchType | undefined
>(undefined)

const EditorProvider = ({
	children,
	data,
}: ProviderProps): React.ReactElement => {
	const [surveyDefinitionState, dispatch] = useImmerReducer(editorReducer, data)

	return (
		<EditorStateContext.Provider value={surveyDefinitionState}>
			<EditorDispatchContext.Provider value={dispatch}>
				{children}
			</EditorDispatchContext.Provider>
		</EditorStateContext.Provider>
	)
}

const useEditorState = (): ApiDataType => {
	const context = React.useContext(EditorStateContext)

	if (context === undefined) {
		throw new Error('useSurveyState must be used within a SurveyProvider')
	}

	return context
}

const useEditorDispatch = (): EditorDispatchType => {
	const context = React.useContext(EditorDispatchContext)

	if (context === undefined) {
		throw new Error('useSurveyDispatch must be used within a SurveyProvider')
	}

	return context
}

export { EditorProvider, useEditorState, useEditorDispatch }

type ProviderProps = {
	children: React.ReactNode
	data: ApiDataType | undefined
}
