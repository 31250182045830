import * as React from 'react'
import * as d3 from 'd3'
import { css } from 'styled-components/macro'
import lodash from 'lodash'

import {
	isChoicesQuestionType,
	ChoiceType,
	isBasicQuestionType,
	isQuestionPositionType,
	isChoicePositionType,
	isChoiceType,
	isBmiTagRangeType,
	BasicQuestionType,
	ChoicesQuestionType,
	QuestionPositionType,
	SurveySubmitType,
	TranslationObjectType,
} from '../../types'

const VisualizedQuestionPaths: React.FC<VisualizedQuestionPathsProps> = ({
	validVisualQuestions,
	translations,
	selectedChoicesContext,
}) => {
	const ref = React.useRef<SVGSVGElement | null>(null)

	const {
		nextQuestion,
		selectedChoices,
		setSelectedChoices,
	} = selectedChoicesContext

	const questionsWithoutDuplicateTags = React.useMemo(
		() =>
			validVisualQuestions.map((question) => {
				if (isChoicesQuestionType(question)) {
					const newQuestion = lodash.cloneDeep(question)
					const newChoices: ChoiceType[] = []

					if (question.choices) {
						question.choices.reduce(
							(choicesSortedBySkips: ChoicesSortedBySkipsType, choice) => {
								if (
									choice.choices?.length ||
									!choicesSortedBySkips[choice.offset]?.includes(choice.tags)
								) {
									const newChoice = lodash.cloneDeep(choice)

									if (choice.choices?.length) {
										const newSubChoices: ChoiceType[] = []

										choice.choices.reduce(
											(
												subChoicesSortedBySkips: ChoicesSortedBySkipsType,
												subChoice
											) => {
												if (
													!subChoicesSortedBySkips[subChoice.offset]?.includes(
														subChoice.tags
													)
												) {
													if (
														typeof subChoicesSortedBySkips[subChoice.offset] ===
														'undefined'
													) {
														subChoicesSortedBySkips[subChoice.offset] = []
													}

													subChoicesSortedBySkips[subChoice.offset].push(
														subChoice.tags
													)

													newSubChoices.push(subChoice)
												}

												return subChoicesSortedBySkips
											},
											{}
										)

										newChoice.choices = newSubChoices
									}

									if (
										typeof choicesSortedBySkips[choice.offset] === 'undefined'
									) {
										choicesSortedBySkips[choice.offset] = []
									}

									choicesSortedBySkips[choice.offset].push(choice.tags)

									newChoices.push(newChoice)
								}

								return choicesSortedBySkips
							},
							{}
						)
					}

					newQuestion.choices = newChoices
					return newQuestion
				}

				return question
			}),
		[validVisualQuestions]
	)

	React.useEffect(() => {
		if (ref.current === null) return

		const svg = d3.select<SVGSVGElement, unknown>(ref.current)
		const selectedColor = '#00719c'
		const hoverColor = '#c70850'

		const previousTransform = (svg
			.select('.container')
			.node() as SVGGElement)?.getCTM()

		svg.selectAll('*').remove()

		const container = svg.append('g').classed('container', true)

		const zoom = d3.zoom<SVGSVGElement, unknown>().on('zoom', (event) => {
			container.attr('transform', event.transform)
		})

		svg.call(zoom)

		if (previousTransform) {
			svg.call(
				zoom.transform,
				d3.zoomIdentity
					.translate(previousTransform.e, previousTransform.f)
					.scale(previousTransform.a)
			)
		}

		const questions = container
			.selectAll('g')
			.data(questionsWithoutDuplicateTags)
			.join('g')
			.classed('question-box', true)
			.attr('id', (question) => question.id)
			.attr(
				'transform',
				(question, questionIndex) =>
					`translate(${questionIndex * (400 + 70) + 50}, 100)`
			)

		questions
			.append('rect')
			.attr('height', (question) => {
				if (isChoicesQuestionType(question)) {
					const numberOfChoices = question.choices.reduce(
						(numberOfChoices: number, choice) => {
							if (choice.choices?.length) {
								numberOfChoices += 1 + choice.choices.length
							} else {
								numberOfChoices++
							}

							return numberOfChoices
						},
						0
					)

					return 45 + 35 * numberOfChoices
				} else if (isBasicQuestionType(question)) {
					const numberOfBmiTags = question.bmiTagRanges.length

					return 45 + 30 * numberOfBmiTags
				} else {
					return 45
				}
			})
			.attr('width', 400)

		questions
			.append('text')
			.classed('question-title', true)
			.text(
				(question) =>
					translations[question.headerTextKey]?.text || 'Question title'
			)
			.attr('transform', 'translate(10, 22)')
			.style('fill', (question) => {
				return selectedChoices.find(
					(selectedChoice) => selectedChoice.choiceId === question.id
				)
					? selectedColor
					: '#000000'
			})
			.each(wrap)

		function onChoiceClick(
			choiceId: string,
			questionId: string,
			questionIndex: number
		) {
			const updatedSelectedChoices = [...selectedChoices]
			const previouslyAnsweredIndex = selectedChoices?.findIndex(
				(selectedChoice) => selectedChoice.questionId === questionId
			)

			if (selectedChoices.length === 0) {
				if (questionId === questionsWithoutDuplicateTags[0].id) {
					updatedSelectedChoices.push({
						questionIndex,
						questionId: questionId,
						choiceId: choiceId,
					})
				}
			} else if (previouslyAnsweredIndex >= 0) {
				const selectedChoice = selectedChoices[previouslyAnsweredIndex]

				if (selectedChoice.choiceId === choiceId) {
					updatedSelectedChoices.splice(previouslyAnsweredIndex)
				} else {
					selectedChoice.choiceId = choiceId
					updatedSelectedChoices.splice(previouslyAnsweredIndex + 1)
				}
			} else if (questionId === nextQuestion?.definition.id) {
				updatedSelectedChoices.push({
					questionIndex,
					questionId: questionId,
					choiceId: choiceId,
				})
			}

			setSelectedChoices(updatedSelectedChoices)
		}

		questions
			.append('g')
			.classed('choices-box', true)
			.attr('transform', 'translate(20,50)')
			.each(function (question, questionIndex) {
				if (isChoicesQuestionType(question)) {
					const choices = d3
						.select(this)
						.selectAll('g')
						.data(question.choices)
						.join('g')
						.classed('choice-box', true)

					choices
						.append('text')
						.text(
							(choice) =>
								translations[choice.headerTextKey]?.text || 'Choice title'
						)
						.attr('dy', '0.32em')
						.classed('choice-title', true)
						.attr('id', (choice) => choice.id)
						.attr('fill', (choice) => {
							return selectedChoices.find(
								(selectedChoice) => selectedChoice.choiceId === choice.id
							)
								? selectedColor
								: '#000000'
						})
						.on('click', (event, choice) => {
							event.stopPropagation()

							onChoiceClick(choice.id, question.id, questionIndex)
						})
						.each(wrap)
						.each(function (choice) {
							const parentContainer = d3.select(this).select(function () {
								return this.closest('.choice-box')
							})

							if (choice.choices?.length && parentContainer) {
								parentContainer
									.selectAll('.sub-choice')
									.data(choice.choices)
									.enter()
									.append('text')
									.attr('transform', 'translate(0, 20)')
									.attr('dy', '0.32em')
									.classed('choice-title', true)
									.attr('id', (subChoice) => subChoice.id)
									.text(
										(subChoice) =>
											translations[subChoice.headerTextKey]?.text ||
											'Subchoice title'
									)
									.each(wrap)
									.attr(
										'transform',
										(choice, choiceIndex) =>
											`translate(10,${choiceIndex * 30 + 30})`
									)
									.on('click', (event, subChoice) => {
										event.stopPropagation()

										onChoiceClick(subChoice.id, question.id, questionIndex)
									})
							}
						})

					choices.attr(
						'transform',
						(choiceContainer, choiceContainerIndex, choiceContainers) => {
							const parentContainer = d3
								.select(choiceContainers[choiceContainerIndex])
								.select((choice, choiceIndex, choices) => {
									const currentChoice = choices[choiceIndex]

									if (currentChoice) {
										return (currentChoice as SVGGElement).closest('.choice-box')
									} else {
										return null
									}
								})

							const previousSibling = parentContainer
								.select((choice, choiceIndex, choices) => {
									const previousSibling = choices[choiceIndex]?.previousSibling

									if (previousSibling) {
										return (previousSibling as SVGGElement).closest(
											'.choice-box'
										)
									} else {
										return null
									}
								})
								.node()

							if (previousSibling) {
								const previousSiblingTransform = (previousSibling as SVGGElement).transform.baseVal.consolidate()
									?.matrix
								const previousSiblingBox = (previousSibling as SVGGElement).getBBox()

								return `translate(0, ${
									(previousSiblingTransform?.f || 0) +
									previousSiblingBox.height +
									10
								})`
							}

							return `translate(0,${choiceContainerIndex * 30})`
						}
					)
				} else if (isBasicQuestionType(question)) {
					const bmiTagRanges = d3
						.select(this)
						.selectAll('g')
						.data(question.bmiTagRanges)
						.join('g')
						.classed('bmi-box', true)
						.attr(
							'transform',
							(bmiRange, bmiIndex: number) => `translate(0, ${bmiIndex * 30})`
						)

					bmiTagRanges
						.append('text')
						.text((bmiTagRange) => bmiTagRange.bmiTag)
						.attr('dy', '0.32em')
						.attr('id', (bmiTagRange) => bmiTagRange.id)
						.attr('fill', (bmiTagRange) => {
							return selectedChoices.find(
								(selectedChoice) => selectedChoice.choiceId === bmiTagRange.id
							)
								? selectedColor
								: '#000000'
						})
						.classed('choice-title', true)
						.on('click', (event, bmiTagRange) => {
							event.stopPropagation()

							onChoiceClick(bmiTagRange.id, question.id, questionIndex)
						})
				}
			})

		const questionsCords = d3
			.selectAll('.question-box')
			.nodes()
			.map((question, questionIndex: number) => {
				const containerPosition = container.node()?.getCTM()
				const questionPosition = (question as SVGGElement).getCTM()
				const questionDimensions = (question as SVGGElement).getBBox()

				// Not a real use-case, if the container is non existant, then none of this code will run anyways [AW]
				if (!containerPosition || !questionPosition || !questionDimensions) {
					return {}
				}

				const relativeQuestionX =
					(questionPosition.e - containerPosition.e) / containerPosition.a
				const relativeQuestionY =
					(questionPosition.f - containerPosition.f) / containerPosition.a

				return {
					questionIndex,
					id: (question as SVGGElement).id,
					x: relativeQuestionX,
					y: relativeQuestionY,
					height: questionDimensions.height,
					width: questionDimensions.width,
				}
			})

		const choiceCords = d3
			.selectAll('.choice-title')
			.nodes()
			.map((choice) => {
				const containerPosition = container.node()?.getCTM()
				const choicePosition = (choice as SVGGElement).getCTM()
				const choiceDimensions = (choice as SVGGElement).getBBox()

				// Not a real use-case, if the container is non existant, then none of this code will run anyways [AW]
				if (!containerPosition || !choicePosition || !choiceDimensions) {
					return {}
				}

				const relativeChoiceX =
					(choicePosition.e - containerPosition.e) / containerPosition.a
				const relativeChoiceY =
					(choicePosition.f - containerPosition.f) / containerPosition.a

				return {
					id: (choice as SVGGElement).id,
					x: relativeChoiceX,
					y: relativeChoiceY,
					height: choiceDimensions.height,
					width: choiceDimensions.width,
				}
			})

		const links = questionsWithoutDuplicateTags.reduce(
			(links: LinkType[], question, questionIndex) => {
				if (isChoicesQuestionType(question)) {
					if (question.choices.length) {
						question.choices.forEach((choice) => {
							let nextQuestion =
								questionsWithoutDuplicateTags[questionIndex + choice.offset + 1]

							if (!nextQuestion) {
								nextQuestion = questionsWithoutDuplicateTags[questionIndex + 1]
							}

							const currentQuestionPosition = questionsCords.find(
								(questionCords) => questionCords.id === question.id
							)
							const nextQuestionPosition = questionsCords.find(
								(questionCords) => questionCords.id === nextQuestion?.id
							)

							const choicePosition = choiceCords.find(
								(choicePos) => choicePos.id === choice.id
							)

							if (
								!choice.choices?.length &&
								isQuestionPositionType(currentQuestionPosition) &&
								isQuestionPositionType(nextQuestionPosition) &&
								isChoicePositionType(choicePosition) &&
								nextQuestionPosition
							) {
								links.push({
									sourceQuestion: {
										...currentQuestionPosition,
										choice: choicePosition,
									},
									targetQuestion: nextQuestionPosition,
								})
							}

							if (choice.choices?.length) {
								choice.choices.forEach((subChoice) => {
									let nextQuestion =
										questionsWithoutDuplicateTags[
											questionIndex + subChoice.offset + 1
										]

									if (!nextQuestion) {
										nextQuestion =
											questionsWithoutDuplicateTags[questionIndex + 1]
									}

									const nextQuestionPosition = questionsCords.find(
										(questionCords) => questionCords.id === nextQuestion?.id
									)

									const subChoicePosition = choiceCords.find(
										(subChoicePos) => subChoicePos.id === subChoice.id
									)

									if (
										isQuestionPositionType(currentQuestionPosition) &&
										isQuestionPositionType(nextQuestionPosition) &&
										isChoicePositionType(subChoicePosition)
									) {
										links.push({
											sourceQuestion: {
												...currentQuestionPosition,
												choice: subChoicePosition,
											},
											targetQuestion: nextQuestionPosition,
										})
									}
								})
							}
						})
					}
				} else if (isBasicQuestionType(question)) {
					const currentQuestionPosition = questionsCords.find(
						(questionCords) => questionCords.id === question.id
					)

					const nextQuestion = questionsWithoutDuplicateTags[questionIndex + 1]

					const nextQuestionPosition = questionsCords.find(
						(questionCords) => questionCords.id === nextQuestion?.id
					)

					if (question.bmiTagRanges.length) {
						question.bmiTagRanges.forEach((bmiTagRange) => {
							const choicePosition = choiceCords.find(
								(choicePos) => choicePos.id === bmiTagRange.id
							)

							if (
								isQuestionPositionType(currentQuestionPosition) &&
								isQuestionPositionType(nextQuestionPosition) &&
								isChoicePositionType(choicePosition)
							) {
								links.push({
									sourceQuestion: {
										...currentQuestionPosition,
										choice: choicePosition,
									},
									targetQuestion: nextQuestionPosition,
								})
							}
						})
					} else {
						if (
							isQuestionPositionType(currentQuestionPosition) &&
							isQuestionPositionType(nextQuestionPosition)
						) {
							links.push({
								sourceQuestion: {
									...currentQuestionPosition,
									choice: {
										id: currentQuestionPosition.id,
										x: currentQuestionPosition.x,
										y: currentQuestionPosition.y,
										height: currentQuestionPosition.height,
										width: currentQuestionPosition.width,
									},
								},
								targetQuestion: nextQuestionPosition,
							})
						}
					}
				}

				return links
			},
			[]
		)

		function drawPath({
			sourceQuestion: source,
			targetQuestion: target,
		}: LinkType) {
			const path = d3.path()

			if (!source.choice) {
				return ''
			}

			path.moveTo(source.choice.x + source.choice.width, source.choice.y)
			path.lineTo(source.x + source.width + 10, source.choice.y)

			if (target.questionIndex - source.questionIndex === 1) {
				path.lineTo(target.x, target.y)
			} else {
				const start = source.x + source.width
				const end = target.x

				return [
					'M',
					source.choice.x + source.choice.width,
					source.choice.y,
					'L',
					source.x + source.width + 10,
					',',
					source.choice.y,
					'A',
					(start - end) / 2,
					',',
					(start - end) / 2,
					0,
					0,
					',',
					start < end ? 1 : 0,
					end,
					',',
					target.y,
				].join(' ')
			}

			return path.toString()
		}

		const drawnLinks = container
			.selectAll('.link')
			.data(links)
			.join('path')
			.classed('link', true)
			.attr('d', (link) => drawPath(link))
			.attr('fill', 'none')
			.each((linkData, linkIndex: number, nodes) => {
				const link = d3.select(nodes[linkIndex])
				const selected = !!selectedChoices.find((selected) => {
					return selected.choiceId === linkData.sourceQuestion.choice?.id
				})

				if (selected) {
					link.attr('stroke', selectedColor)
					link.attr('stroke-width', '3')
				} else {
					link.attr('stroke', '#000000')
				}
			})

		const questionTitles = d3.selectAll('.question-title')
		const choiceTitles = d3.selectAll('.choice-title')

		questionTitles.each((question, questionIndex, questions) => {
			const currentBasicQuestion = d3.select(questions[questionIndex])

			if (isBasicQuestionType(question) && !question.bmiTagRanges.length) {
				currentBasicQuestion.style('cursor', 'pointer')

				currentBasicQuestion.on('click', (event) => {
					event.stopPropagation()

					onChoiceClick(question.id, question.id, questionIndex)
				})

				currentBasicQuestion.on('mouseover', () => {
					currentBasicQuestion.style('fill', hoverColor)

					choiceTitles.each((choice, choiceIndex, nodes) => {
						if (isChoiceType(choice) || isBmiTagRangeType(choice)) {
							const choiceTitle = d3.select(nodes[choiceIndex])
							const isSelected = selectedChoices.find(
								(selectedChoice) => selectedChoice.choiceId === choice.id
							)

							if (isSelected) {
								choiceTitle.style('fill', selectedColor)
							} else {
								choiceTitle.style('fill', '#b8b8b8')
							}
						}
					})

					drawnLinks.each((linkData, linkIndex: number, nodes) => {
						const link = d3.select(nodes[linkIndex])

						link.style('stroke', '#000000')
						link.style('stroke-width', '1')

						const selected = !!selectedChoices.find((selected) => {
							return selected.choiceId === linkData.sourceQuestion.choice?.id
						})

						if (question.id === linkData.sourceQuestion.choice?.id) {
							link.style('stroke', hoverColor)
							link.style('stroke-width', '4')
						} else if (selected) {
							link.style('stroke', selectedColor)
							link.style('stroke-width', '4')
						} else {
							link.style('stroke', '#b8b8b8')
							link.style('stroke-width', '1')
						}
					})
				})

				currentBasicQuestion.on('mouseout', () => {
					const isSelected = selectedChoices.find(
						(selectedChoice) => selectedChoice.choiceId === question.id
					)

					questionTitles.style('fill', '#000000')

					if (isSelected) {
						currentBasicQuestion.style('fill', selectedColor)
					}

					choiceTitles.each((choice, choiceIndex, nodes) => {
						if (isChoiceType(choice) || isBmiTagRangeType(choice)) {
							const isSelected = selectedChoices.find(
								(selectedChoice) => selectedChoice.choiceId === choice.id
							)

							if (isSelected) {
								d3.select(nodes[choiceIndex]).style('fill', selectedColor)
							} else {
								d3.select(nodes[choiceIndex]).style('fill', '#000000')
							}
						}
					})

					drawnLinks.each((linkData, linkIndex: number, nodes) => {
						const link = d3.select(nodes[linkIndex])

						link.style('stroke', '#000000')
						link.style('stroke-width', '1')

						const selectedLink = !!selectedChoices.find((selected) => {
							return selected.choiceId === linkData.sourceQuestion.choice?.id
						})

						if (selectedLink) {
							link.style('stroke', selectedColor)
							link.style('stroke-width', '4')
						}
					})
				})
			}
		})

		choiceTitles
			.on('mouseover', function (event) {
				const hoverId = event.target.id

				choiceTitles.each((choice, choiceIndex, nodes) => {
					if (isChoiceType(choice) || isBmiTagRangeType(choice)) {
						const choiceTitle = d3.select(nodes[choiceIndex])
						const isSelected = selectedChoices.find(
							(selectedChoice) => selectedChoice.choiceId === choice.id
						)

						if (hoverId === choice.id) {
							choiceTitle.style('fill', hoverColor)
						} else if (isSelected) {
							choiceTitle.style('fill', selectedColor)
						} else {
							choiceTitle.style('fill', '#b8b8b8')
						}
					}
				})

				drawnLinks.each((linkData, linkIndex: number, nodes) => {
					const link = d3.select(nodes[linkIndex])

					link.style('stroke', '#000000')
					link.style('stroke-width', '1')

					const selected = !!selectedChoices.find((selected) => {
						return selected.choiceId === linkData.sourceQuestion.choice?.id
					})

					if (hoverId === linkData.sourceQuestion.choice?.id) {
						link.style('stroke', hoverColor)
						link.style('stroke-width', '4')
					} else if (selected) {
						link.style('stroke', selectedColor)
						link.style('stroke-width', '4')
					} else {
						link.style('stroke', '#b8b8b8')
						link.style('stroke-width', '1')
					}
				})
			})
			.on('mouseout', () => {
				choiceTitles.each((choice, choiceIndex, nodes) => {
					if (isChoiceType(choice) || isBmiTagRangeType(choice)) {
						const isSelected = selectedChoices.find(
							(selectedChoice) => selectedChoice.choiceId === choice.id
						)

						if (isSelected) {
							d3.select(nodes[choiceIndex]).style('fill', selectedColor)
						} else {
							d3.select(nodes[choiceIndex]).style('fill', '#000000')
						}
					}
				})

				drawnLinks.each((linkData, linkIndex: number, nodes) => {
					const link = d3.select(nodes[linkIndex])

					link.style('stroke', '#000000')
					link.style('stroke-width', '1')

					const selectedLink = !!selectedChoices.find((selected) => {
						return selected.choiceId === linkData.sourceQuestion.choice?.id
					})

					if (selectedLink) {
						link.style('stroke', selectedColor)
						link.style('stroke-width', '4')
					}
				})
			})

		//"this" is not a paramater, it is written this way for typescript so I can define the type i expect to be sent. [AW]
		function wrap(this: SVGTextElement | null) {
			const svgTextSelection = d3.select(this)
			const textNode = d3.select(this).node()
			let text = d3.select(this).text()

			if (textNode && text) {
				let textLength = textNode.getComputedTextLength()

				while (textLength > 400 - 2 * 5 && text.length > 0) {
					text = text.slice(0, -1)
					svgTextSelection.text(text + '...')
					textLength = textNode.getComputedTextLength()
				}
			}
		}
	}, [
		questionsWithoutDuplicateTags,
		translations,
		selectedChoices,
		setSelectedChoices,
		nextQuestion,
	])

	return (
		<div
			css={css`
				svg {
					border: 2px solid #c0c0c0;
					border-radius: 7px;
					height: 100%;
					width: 100%;

					.question-title {
						font-weight: bold;
					}

					.choice-title {
						&:hover {
							cursor: pointer;
						}
					}
				}

				rect {
					fill: transparent;
					stroke: black;
				}
			`}
			className='visualContainer'
		>
			<svg ref={ref} />
		</div>
	)
}

export default VisualizedQuestionPaths

type LinkType = {
	sourceQuestion: QuestionPositionType
	targetQuestion: QuestionPositionType
}
type VisualizedQuestionPathsProps = {
	validVisualQuestions: (
		| BasicQuestionType
		| ChoicesQuestionType
		| SurveySubmitType
	)[]
	translations: TranslationObjectType
	selectedChoicesContext: {
		nextQuestion:
			| {
					index: number
					definition: BasicQuestionType | ChoicesQuestionType | SurveySubmitType
			  }
			| undefined
		selectedChoices: {
			questionIndex: number
			questionId: string
			choiceId: string
		}[]
		setSelectedChoices: React.Dispatch<
			React.SetStateAction<
				{
					questionIndex: number
					questionId: string
					choiceId: string
				}[]
			>
		>
	}
}

type ChoicesSortedBySkipsType = {
	[key: string]: string[]
}
