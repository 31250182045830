import * as React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useImmerReducer } from 'use-immer'
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
} from 'react-beautiful-dnd'
import { css } from 'styled-components/macro'
import color from 'color'

import { useEditorState, useEditorDispatch } from '../../context/EditorProvider'
import { tagRulesReducer } from '../../context/reducers/tagRulesReducer'

import Button, { ButtonVariant } from '../../components/Button'
import Topbar from '../../components/Topbar'
import Container from '../../components/Container'

import NotFound from '../NotFound'

import { ReactComponent as ClearIcon } from '../../images/clear-icon.svg'
import { ReactComponent as DeleteIcon } from '../../images/delete.svg'

import { SurveyQueryParams, TagRuleType, TagRulesActionType } from '../../types'

const TagRules: React.FC = () => {
	const { surveyDefinition } = useEditorState()

	const rulesWithIds:
		| TagRuleType[]
		| undefined = surveyDefinition?.postProcessingRules.tagRules.map(
		(tagRule) => ({
			...tagRule,
		})
	)

	const [rulesState, rulesDispatch] = useImmerReducer(
		tagRulesReducer,
		rulesWithIds
	)

	if (!surveyDefinition)
		return (
			<TagRuleWrapper>
				<NotFound linkHome />
			</TagRuleWrapper>
		)

	const onAddNewRuleClick = () => {
		rulesDispatch({
			type: 'addNewRule',
		})
	}

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) return

		rulesDispatch({
			type: 'updateIndex',
			payload: {
				tagRuleIndex: result.source.index,
				newIndex: result.destination.index,
			},
		})
	}

	return (
		<TagRuleWrapper tagRules={rulesState}>
			<h1>Tag Rules</h1>
			<p>
				Use tag rules to merge two or more tags to one.
				<br />
				Add the tags you wish to remove along with the tag you want to replace
				them in the list below.
			</p>
			<div
				css={css`
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-bottom: 2px solid #acacac;
				`}
			>
				<h3>Rules</h3>
				<Button
					variant={ButtonVariant.secondary}
					type='button'
					onClick={onAddNewRuleClick}
				>
					Add new rule
				</Button>
			</div>
			{rulesState?.length ? (
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId='droppable_tagRules'>
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{rulesState?.map((tagRule, tagRuleIndex: number) => (
									<Draggable
										key={tagRule.id}
										draggableId={tagRule.id}
										index={tagRuleIndex}
									>
										{(provided, snapshot) => (
											<div
												css={tagRuleStyle}
												className={snapshot.isDragging ? 'dragging' : ''}
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<TagRule
													tagRule={tagRule}
													rulesDispatch={rulesDispatch}
													tagRuleIndex={tagRuleIndex}
												/>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			) : (
				<p>No rules</p>
			)}
		</TagRuleWrapper>
	)
}

const TagRule: React.FC<TagRuleProps> = ({
	tagRule,
	tagRuleIndex,
	rulesDispatch,
}) => {
	const [newFilterState, setNewFilterState] = React.useState<string>('')
	const [newTagToInsertState, setNewTagToInsertState] = React.useState<string>(
		''
	)

	const onAddTagClick = () => {
		rulesDispatch({
			type: 'addTagFilter',
			payload: { tagRuleIndex, newFilterTag: newFilterState },
		})

		setNewFilterState('')
	}

	const onAddReplacementTagClick = () => {
		rulesDispatch({
			type: 'addTagToInsert',
			payload: { tagRuleIndex, newTagToInsert: newTagToInsertState },
		})

		setNewTagToInsertState('')
	}

	return (
		<React.Fragment>
			<div className='tag-rule-header'>
				<h4
					css={css`
						margin: 0;
						margin-right: 0.5rem;
					`}
				>
					Rule #{tagRule.index + 1}
				</h4>
				<button
					type='button'
					onClick={() =>
						rulesDispatch({ type: 'deleteRule', payload: { tagRuleIndex } })
					}
				>
					<DeleteIcon />
				</button>
			</div>
			<div className='tag-rule-row'>
				<div className='tag-rule-column'>
					<label>Tags to replace</label>
					<div className='tag-input'>
						<input
							css={css`
								margin-right: 0.25rem;
							`}
							id={`newFilter_${tagRuleIndex}`}
							value={newFilterState}
							onChange={(event) => setNewFilterState(event.currentTarget.value)}
						/>
						<Button
							type='button'
							variant={ButtonVariant.secondary}
							buttonCss={css`
								padding: 0 0.5rem;
							`}
							onClick={onAddTagClick}
							disabled={!newFilterState}
						>
							Add tag
						</Button>
					</div>
					<div className='tag-rule-cell'>
						{tagRule.tagFilters.map(
							(tagFilter: string, tagFilterIndex: number) => (
								<span
									key={`${tagRuleIndex}_${tagFilterIndex}`}
									className='tag-rule-pill'
								>
									{tagFilter}
									<Button
										type='button'
										variant={ButtonVariant.icon}
										buttonCss={css`
											padding: 0;
											line-height: 0.5;
										`}
										onClick={() => {
											rulesDispatch({
												type: 'deleteTagFilter',
												payload: {
													tagRuleIndex: tagRuleIndex,
													tagIndex: tagFilterIndex,
												},
											})
										}}
									>
										<ClearIcon />
									</Button>
								</span>
							)
						)}
					</div>
				</div>
				<div className='tag-rule-column'>
					<label>Replacement tags</label>
					<div className='tag-input'>
						<input
							css={css`
								margin-right: 0.25rem;
							`}
							id={`newReplacement_${tagRuleIndex}`}
							value={newTagToInsertState}
							onChange={(event) =>
								setNewTagToInsertState(event.currentTarget.value)
							}
						/>
						<Button
							type='button'
							variant={ButtonVariant.secondary}
							buttonCss={css`
								padding: 0 0.5rem;
							`}
							onClick={onAddReplacementTagClick}
							disabled={!newTagToInsertState}
						>
							Add tag
						</Button>
					</div>
					<div className='tag-rule-cell'>
						{tagRule?.tagsToInsert?.map(
							(tagFilter: string, tagFilterIndex: number) => (
								<span
									key={`${tagRuleIndex}_${tagFilterIndex}`}
									className='tag-rule-pill'
								>
									{tagFilter}
									<Button
										type='button'
										variant={ButtonVariant.icon}
										buttonCss={css`
											padding: 0;
											line-height: 0.5;
										`}
										onClick={() => {
											rulesDispatch({
												type: 'deleteTagToInsert',
												payload: {
													tagRuleIndex: tagRuleIndex,
													tagIndex: tagFilterIndex,
												},
											})
										}}
									>
										<ClearIcon />
									</Button>
								</span>
							)
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

const TagRuleWrapper: React.FC<TagRuleWrapperProps> = ({
	tagRules,
	children,
}) => {
	const surveyDispatch = useEditorDispatch()
	const { orgToRequest, deptToRequest } = useParams<SurveyQueryParams>()
	const history = useHistory()
	const { search } = useLocation()

	const onCancelClick = () => {
		window.scrollTo(0, 0)

		history.push({
			pathname: `/editor/${orgToRequest}/${deptToRequest}`,
			search: search,
		})
	}

	const onUpdateClick = () => {
		if (!tagRules) return

		surveyDispatch({ type: 'saveTagRules', payload: { tagRules: tagRules } })

		history.push({
			pathname: `/editor/${orgToRequest}/${deptToRequest}`,
			search: search,
		})
	}

	return (
		<React.Fragment>
			<Topbar>
				<Button type='button' onClick={onCancelClick}>
					Cancel
				</Button>
				{/*validation?*/}
				{tagRules ? (
					<Button
						type='button'
						variant={ButtonVariant.success}
						onClick={onUpdateClick}
					>
						Update
					</Button>
				) : null}
			</Topbar>
			<Container>{children}</Container>
		</React.Fragment>
	)
}

export default TagRules

/* Type definitions for TagRules */

type TagRuleProps = {
	tagRule: TagRuleType
	tagRuleIndex: number
	rulesDispatch: React.Dispatch<TagRulesActionType>
}

type TagRuleWrapperProps = {
	tagRules?: TagRuleType[]
	children: React.ReactNode
}

/* Styling for TagRules */

const tagRuleStyle = css`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	background-color: #efefef;
	border-radius: 5px;
	margin-top: 1rem;

	&.dragging {
		background-color: #dbffe3;
	}

	.tag-rule-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;

		h4 {
			border-bottom: 2px solid #a80047;
		}

		> button {
			background-color: #a80047;
			display: flex;
			align-items: center;
			padding: 0.25rem 0.3rem;
			line-height: 1.5;
			border: 1px solid transparent;
			border-radius: 5px;
			cursor: pointer;

			svg {
				fill: white;
			}

			&:hover {
				background-color: ${color('#a80047').darken(0.6).hex()};

				&:focus,
				&:active {
					background-color: ${color('#a80047').darken(0.1).hex()} svg {
						fill: ${color('#ffffff').darken(0.1).hex()};
					}
				}
			}
		}
	}

	.tag-rule-row {
		display: flex;
		align-items: flex-start;

		label {
			font-size: 0.9rem;
		}

		input {
			border: 0;
			border-bottom: 2px solid #d3d3d3;
			padding: 10px;
		}

		.tag-rule-column {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			width: 100%;

			&:not(:last-child) {
				margin-right: 1rem;
				border-right: 2px solid #acacac;
			}

			.tag-input {
				display: flex;
			}

			.tag-rule-cell {
				display: flex;
				flex-wrap: wrap;
				margin: 1rem 0;

				.tag-rule-pill {
					display: flex;
					align-items: center;
					background-color: #f4be8c;
					border-radius: 15px;
					padding: 0.15rem 0.5rem;
					font-size: 0.75rem;
					margin: 0.5rem;
				}
			}
		}
	}
`
