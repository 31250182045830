import * as React from 'react'
import { css } from 'styled-components/macro'
import {
	DropResult,
	DragDropContext,
	Droppable,
	Draggable,
} from 'react-beautiful-dnd'
import useFetch, { CachePolicies } from 'use-http'

import {
	focusOptions,
	formOptions,
	equipmentOptions,
	ageGroupOptions,
	levelOptions,
	startPositionOptions,
} from '../../constants'

import { useEditorState } from '../../context/EditorProvider'

import IdGenerator from '../../utils/IdGenerator'

import Button, { ButtonVariant } from '../../components/Button'
import Spinner from '../../components/Spinner'
import VariantSelect from '../../components/VariantSelect'
import TextInput from '../../components/TextInput'
import TextArea from '../../components/TextArea'

import ImageUploadFields from './ImageUploadFields'
import ScreeningProperties from './ScreeningProperties'

import { ReactComponent as ArrowDownIcon } from '../../images/arrow-down.svg'
import { ReactComponent as DeleteIcon } from '../../images/delete.svg'

import {
	ChoiceType,
	ChoicesQuestionType,
	QuestionActionType,
	TranslationsType,
	TranslationActionType,
	RecommendationPropertyType,
} from '../../types'

const ChoicesFields: React.FC<ChoicesFieldsProps> = ({
	isLoggedIn,
	parentChoiceIndex,
	choices,
	questionContext,
	translationContext,
}) => {
	const { questionState, questionDispatch } = questionContext
	const [collapsedState, setCollapsed] = React.useState(choices.map(() => true))

	const { translationDispatch } = translationContext

	const onAddClick = () => {
		const translationKeys = {
			headerTextKey: `${IdGenerator()}${Date.now()}`,
			ingressTextKey: `${IdGenerator()}${Date.now()}`,
		}

		questionDispatch({
			type: 'addChoice',
			payload: {
				parentChoiceIndex: parentChoiceIndex,
				...translationKeys,
			},
		})

		translationDispatch({
			type: 'addNewTranslation',
			payload: {
				...translationKeys,
			},
		})

		setCollapsed([...collapsedState, true])
	}

	const onDragEnd = (result: DropResult) => {
		if (
			typeof result?.destination?.index !== 'number' ||
			result?.destination?.index < 0
		) {
			return
		}

		questionDispatch({
			type: 'updateChoicePosition',
			payload: {
				parentChoiceIndex: parentChoiceIndex,
				oldIndex: result.source.index,
				newIndex: result.destination.index,
			},
		})

		const newCollapsedState = [...collapsedState]
		const elementToMove = newCollapsedState.splice(result.source.index, 1)[0]

		newCollapsedState.splice(result.destination.index, 0, elementToMove)

		setCollapsed(newCollapsedState)
	}

	const onCollapseClick = (choiceIndex: number) => {
		const updatedCollapsed = [...collapsedState]

		updatedCollapsed[choiceIndex] = !updatedCollapsed[choiceIndex]

		setCollapsed(updatedCollapsed)
	}

	return (
		<ul
			css={[
				css`
					list-style: none;
					padding: 0;
					margin-top: 3rem;
				`,
				typeof parentChoiceIndex === 'number' &&
					css`
						margin-left: 4rem;
					`,
			]}
		>
			<div
				css={css`
					display: flex;
					align-items: center;
					padding-bottom: 0.5rem;
					border-bottom: 2px solid black;

					> h3 {
						flex-grow: 1;
						margin: 0;
					}
				`}
			>
				<h3>
					{typeof parentChoiceIndex === 'number' ? 'Subchoices' : 'Choices'}
				</h3>
				<div>
					<Button
						type='button'
						onClick={() => setCollapsed(collapsedState.map(() => false))}
						buttonCss={css`
							margin-right: 1rem;
						`}
					>
						Open all
					</Button>
					<Button
						type='button'
						onClick={() => setCollapsed(collapsedState.map(() => true))}
					>
						Collapse all
					</Button>
				</div>
			</div>
			{typeof parentChoiceIndex === 'number' ? (
				<SubQuestionFields
					parentChoiceIndex={parentChoiceIndex}
					questionContext={questionContext}
					translationContext={translationContext}
				/>
			) : null}
			{typeof parentChoiceIndex !== 'number' &&
			questionState.choices.length &&
			questionState.choicesVariant !== 'slider' ? (
				<li
					css={css`
						margin: 1rem 0;
					`}
				>
					<LayoutSelect
						questionState={questionState}
						questionDispatch={questionDispatch}
					/>
				</li>
			) : null}
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId='droppable_choices'>
					{(provided) => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{choices.map((choice, index: number) => (
								<Choice
									key={index}
									choices={choices}
									choice={choice}
									choiceIndex={index}
									questionContext={questionContext}
									translationContext={translationContext}
									parentChoiceIndex={parentChoiceIndex}
									isLoggedIn={isLoggedIn}
									onCollapseClick={onCollapseClick}
									collapsed={collapsedState[index]}
								/>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			<div
				css={css`
					display: flex;
					justify-content: flex-end;
					margin-top: 1rem;
				`}
			>
				<Button
					type='button'
					variant={ButtonVariant.secondary}
					onClick={() => onAddClick()}
				>
					{typeof parentChoiceIndex === 'number'
						? 'Add subchoice'
						: 'Add choice'}
				</Button>
			</div>
		</ul>
	)
}

export default ChoicesFields

const Choice: React.FC<ChoiceProps> = ({
	choice,
	choiceIndex,
	questionContext,
	translationContext,
	parentChoiceIndex,
	isLoggedIn,
	onCollapseClick,
	collapsed,
}) => {
	const { surveyDefinition } = useEditorState()
	const { translationState, translationDispatch } = translationContext
	const { questionState, questionDispatch } = questionContext
	const currentLanguage = 'en-US'
	const currentLanguageTranslations = translationState[currentLanguage]

	const {
		request: screeningPropertiesRequest,
		response: screeningPropertiesResponse,
	} = useFetch(
		`${process.env.REACT_APP_SURVEY_API_URL}/api/survey/screening`,
		{
			credentials: 'include',
			cachePolicy: CachePolicies.NO_CACHE,
		},
		[]
	)

	if (!surveyDefinition || !translationState || !currentLanguageTranslations) {
		return null
	}

	const offsetQuestions = surveyDefinition.questions
		.slice(questionState.order, surveyDefinition.questions.length - 1)
		.filter((question) => question.visible)

	const onDeleteClick = () => {
		questionDispatch({
			type: 'deleteChoice',
			payload: {
				parentChoiceIndex,
				choiceIndex,
			},
		})

		translationDispatch({
			type: 'deleteTranslation',
			payload: {
				headerTextKey: choice.headerTextKey,
				ingressTextKey: choice.ingressTextKey,
			},
		})
	}

	const onAddClick = () => {
		const headerTextKey = `${IdGenerator()}${Date.now()}`
		const ingressTextKey = `${IdGenerator()}${Date.now()}`

		questionDispatch({
			type: 'addChoice',
			payload: {
				parentChoiceIndex: choiceIndex,
				headerTextKey,
				ingressTextKey,
			},
		})

		translationDispatch({
			type: 'addNewTranslation',
			payload: {
				headerTextKey,
				ingressTextKey,
			},
		})
	}

	const onNickNameChange = (text: string, textKey: string | undefined) => {
		if (textKey) {
			translationDispatch({
				type: 'updateTranslation',
				payload: {
					key: textKey,
					translateTo: currentLanguage,
					text,
				},
			})
		} else {
			const newTextKey = `${IdGenerator()}${Date.now()}`

			questionDispatch({
				type: 'updateChoice',
				payload: {
					parentChoiceIndex,
					choiceIndex,
					nicknameTextKey: newTextKey,
				},
			})

			translationDispatch({
				type: 'addNewTranslation',
				payload: {
					nicknameTextKey: newTextKey,
					text,
				},
			})
		}
	}

	if (screeningPropertiesRequest.loading) {
		return <Spinner />
	}

	const choiceHeader =
		currentLanguageTranslations[choice.headerTextKey]?.text !== ''
			? currentLanguageTranslations[choice.headerTextKey]?.text
			: '<Missing header>'

	return (
		<Draggable draggableId={'choice_' + choiceIndex} index={choiceIndex}>
			{(provided, snapshot) => (
				<li
					css={css`
						margin-top: 2rem;
					`}
					ref={provided.innerRef}
					{...provided.draggableProps}
				>
					<div
						css={[
							css`
								background-color: #34373b;
								color: white;
								display: flex;
								padding: 0.5rem 1.5rem;
								align-items: center;
								border-radius: 7px 7px 0 0;

								svg {
									fill: white;
								}
							`,
							collapsed &&
								css`
									border-radius: 7px;
								`,
						]}
						className={snapshot.isDragging ? 'dragging' : ''}
						{...provided.dragHandleProps}
					>
						<div>
							<h4
								css={css`
									margin: 0;
								`}
							>
								{choiceHeader}
							</h4>
							{choice?.choices?.length ? (
								<small>
									Contains {choice.choices.length} subchoice
									{choice.choices.length > 1 ? 's' : ''}
								</small>
							) : null}
						</div>
						<Button
							buttonCss={css`
								margin-left: auto;
								margin-right: 1rem;
							`}
							variant={ButtonVariant.icon}
							onClick={() => onDeleteClick()}
						>
							<DeleteIcon />
						</Button>
						<Button
							variant={ButtonVariant.icon}
							onClick={() => onCollapseClick(choiceIndex)}
						>
							<ArrowDownIcon
								css={
									collapsed
										? css`
												transform: rotate(180deg);
										  `
										: css``
								}
							/>
						</Button>
					</div>
					{collapsed ? null : (
						<div
							css={[
								css`
									background-color: #f5f5f5;
									padding: 1rem 1.5rem;
									border-radius: 0 0 7px 7px;
								`,
								typeof parentChoiceIndex === 'number' &&
									css`
										background-color: #e0e0e0;
									`,
							]}
						>
							<div css={listContainerStyle}>
								<div
									css={css`
										display: flex;
										flex-direction: column;
										margin-right: 1rem;

										> label {
											font-size: 0.9rem;
										}
									`}
								>
									<label
										htmlFor='offset-select'
										css={css`
											margin-right: 0.5rem;
										`}
									>
										Next Question
									</label>
									<select
										id='offset-select'
										value={choice.offset}
										onChange={(event) => {
											questionDispatch({
												type: 'updateChoice',
												payload: {
													parentChoiceIndex: parentChoiceIndex,
													choiceIndex: choiceIndex,
													offset: parseInt(event.currentTarget.value),
												},
											})
										}}
									>
										{offsetQuestions.map((_, index: number) => {
											const nextQuestion = offsetQuestions[index + 1]
												? offsetQuestions[index + 1]
												: surveyDefinition.questions[
														surveyDefinition.questions.length - 1
												  ]

											return (
												<option key={index} value={index}>
													{`${
														currentLanguageTranslations[
															nextQuestion.headerTextKey
														]?.text !== ''
															? currentLanguageTranslations[
																	nextQuestion.headerTextKey
															  ]?.text
															: '<Missing title>'
													} (Skipping ${index} question${
														index === 1 ? '' : 's'
													})`}
												</option>
											)
										})}
									</select>
								</div>
							</div>
							<TextInput
								label='Header'
								placeholder='Choice header'
								id={choice.headerTextKey}
								value={
									currentLanguageTranslations[choice.headerTextKey]?.text || ''
								}
								onChange={(event) => {
									translationDispatch({
										type: 'updateTranslation',
										payload: {
											key: choice.headerTextKey,
											translateTo: currentLanguage,
											text: event.currentTarget.value,
										},
									})
								}}
							/>
							<TextInput
								id={choice.nicknameTextKey}
								label='Alternate shortened header'
								value={
									currentLanguageTranslations[choice.nicknameTextKey]?.text ||
									''
								}
								placeholder='Choice short header'
								onChange={(event) => {
									onNickNameChange(
										event.currentTarget.value,
										choice.nicknameTextKey
									)
								}}
							/>
							<TextArea
								label='Choice Ingress'
								placeholder='Choice ingress'
								id={choice.ingressTextKey}
								value={
									currentLanguageTranslations[choice.ingressTextKey]?.text || ''
								}
								rows={4}
								onChange={(event) => {
									translationDispatch({
										type: 'updateTranslation',
										payload: {
											key: choice.ingressTextKey,
											translateTo: currentLanguage,
											text: event.currentTarget.value,
										},
									})
								}}
							/>
							<TextInput
								label='Tags'
								placeholder='Choice tags'
								id={
									'choice-tag_' +
									choiceIndex +
									(typeof parentChoiceIndex !== 'undefined' &&
									parentChoiceIndex >= 0
										? `_${parentChoiceIndex}`
										: '')
								}
								value={choice.tags || ''}
								onChange={(event) => {
									questionDispatch({
										type: 'updateChoice',
										payload: {
											parentChoiceIndex: parentChoiceIndex,
											choiceIndex: choiceIndex,
											tags: event.currentTarget.value,
										},
									})
								}}
							/>
							<ImageUploadFields
								questionContext={{
									state: choice,
									questionDispatch,
								}}
								choiceIndex={choiceIndex}
								parentChoiceIndex={parentChoiceIndex}
								isLoggedIn={isLoggedIn}
							/>
							<div
								css={css`
									--grid-layout-gap: 10px;
									--grid-column-count: 1;
									--gap-count: calc(var(--grid-column-count) - 1);
									--total-gap-width: calc(
										var(--gap-count) * var(--grid-layout-gap)
									);
									--grid-item--max-width: calc(
										(100% - var(--total-gap-width)) / var(--grid-column-count)
									);
									display: grid;
									grid-template-columns: repeat(
										auto-fill,
										minmax(max(var(--grid-item--max-width)), 1fr)
									);
									grid-gap: var(--grid-layout-gap);
									margin: 1rem 0;
									@media only screen and (min-width: 766px) {
										--grid-column-count: 2;
									}
								`}
							>
								<Properties
									choiceIndex={choiceIndex}
									parentChoiceIndex={parentChoiceIndex}
									properties={choice.properties.recommendation ?? ''}
									questionDispatch={questionDispatch}
								/>
								<ScreeningProperties
									screeningProperties={screeningPropertiesResponse?.data}
									selectedProperties={choice.properties.screening}
									choiceIndex={choiceIndex}
									parentChoiceIndex={parentChoiceIndex}
									questionDispatch={questionDispatch}
								/>
							</div>
							{choice.choices?.length ? (
								<ul
									css={css`
										list-style: none;
										padding: 0;
										margin-top: 3rem;
									`}
								>
									<ChoicesFields
										isLoggedIn={isLoggedIn}
										parentChoiceIndex={choiceIndex}
										choices={choice.choices}
										questionContext={questionContext}
										translationContext={translationContext}
									/>
								</ul>
							) : null}
							{!choice.choices?.length &&
							typeof parentChoiceIndex === 'undefined' ? (
								<div css={listContainerStyle}>
									<Button
										variant={ButtonVariant.secondary}
										buttonCss={css`
											margin-left: auto;
										`}
										onClick={() => onAddClick()}
									>
										Add subchoice
									</Button>
								</div>
							) : null}
						</div>
					)}
				</li>
			)}
		</Draggable>
	)
}

const SubQuestionFields: React.FC<SubQuestionFieldsProps> = ({
	parentChoiceIndex,
	questionContext,
	translationContext,
}) => {
	const { questionState, questionDispatch } = questionContext
	const currentChoice = questionState.choices[parentChoiceIndex]

	const { translationState, translationDispatch } = translationContext
	const currentLanguage = 'en-US'
	const currentLanguageTranslations = translationState[currentLanguage]

	const subQuestionHeaderTextKey = currentChoice?.subQuestionHeaderTextKey
	const subQuestionNicknameTextKey = currentChoice?.subQuestionNicknameTextKey
	const subQuestionIngressTextKey = currentChoice?.subQuestionIngressTextKey

	const subQuestionHeader = subQuestionHeaderTextKey
		? currentLanguageTranslations[subQuestionHeaderTextKey]?.text
		: ''
	const subQuestionNickname = subQuestionNicknameTextKey
		? currentLanguageTranslations[subQuestionNicknameTextKey]?.text
		: ''
	const subQuestionIngress = subQuestionIngressTextKey
		? currentLanguageTranslations[subQuestionIngressTextKey]?.text
		: ''

	const onHeaderChange = (text: string, textKey: string | undefined) => {
		if (textKey) {
			translationDispatch({
				type: 'updateTranslation',
				payload: {
					key: textKey,
					translateTo: currentLanguage,
					text,
				},
			})
		} else {
			const newTextKey = `${IdGenerator()}${Date.now()}`

			questionDispatch({
				type: 'updateChoice',
				payload: {
					choiceIndex: parentChoiceIndex,
					subQuestionHeaderTextKey: newTextKey,
				},
			})

			translationDispatch({
				type: 'addNewTranslation',
				payload: {
					subQuestionHeaderTextKey: newTextKey,
					text,
				},
			})
		}
	}

	const onNicknameChange = (text: string, textKey: string | undefined) => {
		if (textKey) {
			translationDispatch({
				type: 'updateTranslation',
				payload: {
					key: textKey,
					translateTo: currentLanguage,
					text,
				},
			})
		} else {
			const newTextKey = `${IdGenerator()}${Date.now()}`

			questionDispatch({
				type: 'updateChoice',
				payload: {
					choiceIndex: parentChoiceIndex,
					subQuestionNicknameTextKey: newTextKey,
				},
			})

			translationDispatch({
				type: 'addNewTranslation',
				payload: {
					subQuestionNicknameTextKey: newTextKey,
					text,
				},
			})
		}
	}

	const onIngressChange = (text: string, textKey: string | undefined) => {
		if (textKey) {
			translationDispatch({
				type: 'updateTranslation',
				payload: {
					key: textKey,
					translateTo: currentLanguage,
					text,
				},
			})
		} else {
			const newTextKey = `${IdGenerator()}${Date.now()}`

			questionDispatch({
				type: 'updateChoice',
				payload: {
					choiceIndex: parentChoiceIndex,
					subQuestionIngressTextKey: newTextKey,
				},
			})

			translationDispatch({
				type: 'addNewTranslation',
				payload: {
					subQuestionIngressTextKey: newTextKey,
					text,
				},
			})
		}
	}

	return (
		<React.Fragment>
			<TextInput
				id={currentChoice.headerTextKey}
				label='Header'
				placeholder='Sub-question header'
				value={subQuestionHeader}
				onChange={(event) =>
					onHeaderChange(event.currentTarget.value, subQuestionHeaderTextKey)
				}
			/>
			<TextInput
				id={currentChoice.nicknameTextKey}
				label='Alternate shortened header'
				value={subQuestionNickname}
				placeholder='Sub-question short header'
				onChange={(event) =>
					onNicknameChange(
						event.currentTarget.value,
						subQuestionNicknameTextKey
					)
				}
			/>
			<TextArea
				id={currentChoice.ingressTextKey}
				label='Ingress'
				value={subQuestionIngress}
				placeholder='Sub-question ingress'
				rows={4}
				onChange={(event) =>
					onIngressChange(event.currentTarget.value, subQuestionIngressTextKey)
				}
			/>
			<VariantSelect
				questionDispatch={questionDispatch}
				variantObject={questionState.choices[parentChoiceIndex]}
				choiceIndex={parentChoiceIndex}
			/>
		</React.Fragment>
	)
}

const Properties: React.FC<PropertiesProps> = ({
	choiceIndex,
	parentChoiceIndex,
	properties,
	questionDispatch,
}) => {
	const propertyDefinitions = [
		{
			type: 'Focus',
			propertyDefinition: focusOptions,
		},
		{
			type: 'Form',
			propertyDefinition: formOptions,
		},
		{
			type: 'Equipment',
			propertyDefinition: equipmentOptions,
		},
		{
			type: 'Age group',
			propertyDefinition: ageGroupOptions,
		},
		{
			type: 'Difficulty level',
			propertyDefinition: levelOptions,
		},
		{
			type: 'Start position',
			propertyDefinition: startPositionOptions,
		},
	]

	const [propertyType, setPropertyType] = React.useState<string | undefined>(
		undefined
	)
	const propertyToRender = propertyDefinitions.find(
		(propertyDefinition) => propertyDefinition.type === propertyType
	)
	const [grade, setGrade] = React.useState<number>(0)
	const [propertyId, setPropertyId] = React.useState<string | undefined>(
		undefined
	)

	const onAddClick = () => {
		questionDispatch({
			type: 'addChoiceProperties',
			payload: {
				parentChoiceIndex,
				choiceIndex,
				type: propertyToRender?.type,
				id: propertyId,
				grade: grade,
			},
		})

		setGrade(0)
		setPropertyType('Add property type')
	}

	const onAddPropertyChange = (newType: string) => {
		const newPropertyId = propertyDefinitions.find(
			(propertyDefinition) => propertyDefinition.type === newType
		)

		setPropertyType(newType)
		if (newPropertyId) {
			setPropertyId(newPropertyId.propertyDefinition[0].id)
		}
	}

	return (
		<React.Fragment>
			<div>
				<label
					css={css`
						font-size: 0.9rem;
					`}
					htmlFor='properties-area'
				>
					Properties
				</label>
				<select
					defaultValue='Add property type'
					value={propertyType}
					onChange={(event) => onAddPropertyChange(event.currentTarget.value)}
				>
					<option disabled value='Add property type'>
						Add property type
					</option>
					{propertyDefinitions.map((property, index) => (
						<option key={index} value={property.type}>
							{property.type}
						</option>
					))}
				</select>
				{propertyToRender !== undefined ? (
					<div>
						<select
							value={propertyId}
							onChange={(event) => setPropertyId(event.currentTarget.value)}
						>
							{propertyToRender.propertyDefinition.map((propertyField) => (
								<option key={propertyField.id} value={propertyField.id}>
									{propertyField.description}
								</option>
							))}
						</select>
						<label>Grade:</label>
						<input
							min={-100}
							max={100}
							type='number'
							value={grade}
							onChange={(event) =>
								setGrade(parseInt(event.currentTarget.value))
							}
						/>
						<button type='button' onClick={() => onAddClick()}>
							Add
						</button>
					</div>
				) : null}
				<TextArea
					inputCss={css`
						margin: 0.5rem 0;
					`}
					name='properties'
					id='properties-area'
					value={JSON.stringify(properties)}
					readOnly
					cols={30}
					rows={5}
				/>
				<Button
					type='button'
					variant={ButtonVariant.secondary}
					onClick={() => {
						questionDispatch({
							type: 'deleteChoiceProperties',
							payload: { parentChoiceIndex, choiceIndex },
						})
					}}
				>
					Clear properties
				</Button>
			</div>
		</React.Fragment>
	)
}

const LayoutSelect: React.FC<LayoutSelectProps> = ({
	questionState,
	questionDispatch,
}) => {
	return (
		<div
			css={css`
				padding: 0 1rem;
			`}
		>
			<label
				css={css`
					font-size: 0.9rem;
				`}
			>
				Layout
			</label>
			<div
				css={css`
					margin-left: 0.5rem;
				`}
			>
				<div>
					<input
						type='radio'
						id='vertical-align'
						value='vertical'
						name='alignment'
						css={css`
							margin-right: 0.5rem;
						`}
						checked={questionState?.alignment === 'vertical'}
						onChange={(event) => {
							questionDispatch({
								type: 'updateQuestion',
								payload: { alignment: event?.currentTarget?.value },
							})
						}}
					/>
					<label
						css={css`
							margin-right: 1.5rem;
						`}
						htmlFor='vertical-align'
					>
						Vertical
					</label>
				</div>
				<div>
					<input
						type='radio'
						id='horizontal-align'
						value='horizontal'
						name='alignment'
						css={css`
							margin-right: 0.5rem;
						`}
						checked={questionState?.alignment === 'horizontal'}
						onChange={(event) => {
							questionDispatch({
								type: 'updateQuestion',
								payload: { alignment: event?.currentTarget?.value },
							})
						}}
					/>
					<label htmlFor='horizontal-align'>Horizontal</label>
				</div>
			</div>
		</div>
	)
}

type ChoicesFieldsProps = {
	isLoggedIn: boolean
	parentChoiceIndex?: number
	choices: Array<ChoiceType>
	questionContext: {
		questionState: ChoicesQuestionType
		questionDispatch: React.Dispatch<QuestionActionType>
	}
	translationContext: {
		translationState: TranslationsType
		translationDispatch: React.Dispatch<TranslationActionType>
	}
}

type ChoiceProps = {
	choice: ChoiceType
	choices: Array<ChoiceType>
	parentChoiceIndex?: number
	choiceIndex: number
	questionContext: {
		questionState: ChoicesQuestionType
		questionDispatch: React.Dispatch<QuestionActionType>
	}
	translationContext: {
		translationState: TranslationsType
		translationDispatch: React.Dispatch<TranslationActionType>
	}
	isLoggedIn: boolean
	onCollapseClick: (arg: number) => void
	collapsed: boolean
}

type SubQuestionFieldsProps = {
	parentChoiceIndex: number
	questionContext: {
		questionState: ChoicesQuestionType
		questionDispatch: React.Dispatch<QuestionActionType>
	}
	translationContext: {
		translationState: TranslationsType
		translationDispatch: React.Dispatch<TranslationActionType>
	}
}

type PropertiesProps = {
	choiceIndex: number
	parentChoiceIndex?: number
	properties: RecommendationPropertyType[]
	questionDispatch: React.Dispatch<QuestionActionType>
}

type LayoutSelectProps = {
	questionState: ChoicesQuestionType
	questionDispatch: React.Dispatch<QuestionActionType>
}

const listContainerStyle = css`
	display: flex;
	align-items: center;

	> span {
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`
