import React from 'react'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import {
	QueryParamProvider,
	useQueryParams,
	StringParam,
} from 'use-query-params'
import { Normalize } from 'styled-normalize'
import {
	createGlobalStyle,
	DefaultTheme,
	ThemeProvider,
	css,
} from 'styled-components/macro'
import color from 'color'

import SurveyHandler from './screens/SurveyHandler'
import SurveyEditor from './screens/SurveyEditor'
import NotFound from './screens/NotFound'
import Container from './components/Container'
import { SurveyFetcherProvider } from './hooks/useSurveyFetcher'

const App = (): React.ReactElement => {
	const [queryParams] = useQueryParams({
		color: StringParam,
		color2: StringParam,
		colorBackground: StringParam,
	})

	const {
		color: urlPrimaryColor,
		color2: urlAccentColor,
		colorBackground,
	} = queryParams
	const { pathname, search } = useLocation()

	const primaryColor = color(urlPrimaryColor || '#f0f0f0')
	const secondaryColor = color(urlAccentColor || '#34373b')

	const theme: DefaultTheme = {
		primaryColor: primaryColor.hex(),
		textColorForPrimary: primaryColor.isDark() ? '#ffffff' : 'rgba(0,0,0,0.87)',
		secondaryColor: secondaryColor.hex(),
		textColorForSecondary: secondaryColor.isDark()
			? '#ffffff'
			: 'rgba(0,0,0,0.87)',
		colorBackground: colorBackground || '#f5f5f5',
		defaultTextColor: 'rgba(0,0,0,0.87)',
		fadedTextColor: 'rgba(0,0,0,0.6)',
	}

	return (
		<ThemeProvider theme={theme}>
			<Normalize />
			<GlobalStyle colorBackground={colorBackground || '#f5f5f5'} />
			<QueryParamProvider ReactRouterRoute={Route}>
				<Switch>
					<Redirect
						from='/:url*(/+)'
						to={{ pathname: pathname.slice(0, -1), search: search }}
					/>
					<Route path='/editor/:orgToRequest?/:deptToRequest?'>
						<div
							css={css`
								background-color: white;
							`}
						>
							<SurveyEditor />
						</div>
					</Route>
					<Route path='/SurveyHandler'>
						<SurveyFetcherProvider>
							<SurveyHandler />
						</SurveyFetcherProvider>
					</Route>
					<Route>
						<Container>
							<NotFound />
						</Container>
					</Route>
				</Switch>
			</QueryParamProvider>
		</ThemeProvider>
	)
}

export default App

const GlobalStyle = createGlobalStyle`
	html {
		line-height: 1.5;
		background-color: ${(props: { colorBackground: string }) =>
			props.colorBackground};
	}

	select,
	button,
	input {
		color: rgba(0,0,0,0.87);

		&[type='date']::-webkit-date-and-time-value {
			text-align: left;
		}
	}

	body {
		font-size: 16px;
		font-family: 'Open Sans';

		p {
			color: rgba(0, 0, 0, 0.87);
		}

		h1 {
			font-size: 1.25rem;
			line-height: 1.75rem;
		}
	}

	@media only screen and (min-width: 766px) {
	  body {
		font-size: 20px;
	  }
	}
`
