import * as React from 'react'
import { css } from 'styled-components/macro'

import IdGenerator from '../../utils/IdGenerator'
import { getObjectById } from '../../utils/getObject'
import { transformValuesToArray } from '../../utils/transformValuesToArray'

import useDetectOutsideClick from '../../hooks/useDetectOutsideClick'

import { ReactComponent as DeleteIcon } from '../../images/delete.svg'

import {
	ScreeningPropertySettingsType,
	QuestionActionType,
	ScreeningPropertyType,
} from '../../types'

const ScreeningProperties: React.FC<ScreeningPropertiesProps> = ({
	screeningProperties,
	choiceIndex,
	parentChoiceIndex,
	questionDispatch,
	selectedProperties,
}) => {
	const [isPopoverOpen, toggleIsPopoverOpen] = React.useState(false)

	if (!screeningProperties) {
		return null
	}

	const onContextPropertySelect = (
		selectedPropertyId: string,
		selectedPropertyParentId?: string
	) => {
		const propertySettings = getObjectById(
			selectedPropertyId,
			screeningProperties
		)

		toggleIsPopoverOpen(false)
		questionDispatch({
			type: 'addScreeningProperty',
			payload: {
				choiceIndex,
				parentChoiceIndex,
				selectedPropertyId,
				selectedPropertyParentId,
				propertySettings,
			},
		})
	}

	const onContextPropertyValueUpdate = (
		selectedPropertyId: string,
		value: string
	) => {
		questionDispatch({
			type: 'updateScreeningPropertyValue',
			payload: {
				parentChoiceIndex,
				choiceIndex,
				selectedPropertyId,
				value,
			},
		})
	}

	const onContextPropertyDelete = (selectedPropertyId: string) => {
		questionDispatch({
			type: 'deleteScreeningProperty',
			payload: {
				parentChoiceIndex,
				choiceIndex,
				selectedPropertyId,
			},
		})
	}

	return (
		<div
			css={css`
				display: flex;
				flex-direction: column;
			`}
		>
			<span
				css={css`
					margin: 0 0 0.25rem;
					font-size: 0.9rem;
				`}
			>
				Screening properties
			</span>
			<CustomDropdown isOpen={isPopoverOpen} toggleIsOpen={toggleIsPopoverOpen}>
				<div css={propertySelectContainerStyle}>
					{screeningProperties.map((property) => (
						<button
							key={IdGenerator()}
							value={property.id}
							onClick={() => {
								onContextPropertySelect(property.id)
							}}
							css={propertySelectStyle}
						>
							{property.title}
						</button>
					))}
				</div>
			</CustomDropdown>
			{selectedProperties.map((property) => {
				const propertySettings = getObjectById(property.id, screeningProperties)

				if (!propertySettings) {
					return null
				}

				return (
					<ScreeningPropertyFields
						key={IdGenerator()}
						screeningProperties={screeningProperties}
						selectedProperty={property}
						propertySettings={propertySettings}
						onContextPropertySelect={onContextPropertySelect}
						onContextPropertyValueUpdate={onContextPropertyValueUpdate}
						onContextPropertyDelete={onContextPropertyDelete}
					/>
				)
			})}
		</div>
	)
}

const ScreeningPropertyFields: React.FC<ScreeningPropertyFieldsProps> = ({
	screeningProperties,
	propertySettings,
	selectedProperty,
	onContextPropertySelect,
	onContextPropertyValueUpdate,
	onContextPropertyDelete,
}) => {
	const [isPopoverOpen, toggleIsPopoverOpen] = React.useState(false)

	return propertySettings?.children?.length ? (
		<div
			css={css`
				margin-top: 1rem;
				margin-left: 1rem;
			`}
		>
			<div
				css={css`
					display: flex;
					align-items: center;
				`}
			>
				<span
					css={css`
						margin: 0 0 0.25rem;
						font-size: 0.9rem;
						margin-right: 0.5rem;
					`}
				>
					{propertySettings.title}
				</span>
				<button
					type='button'
					css={css`
						display: flex;
						background-color: transparent;
						border: 0;

						@media (hover: hover) {
							&:hover {
								cursor: pointer;
							}
						}
					`}
					onClick={() => {
						onContextPropertyDelete(selectedProperty.id)
					}}
				>
					<DeleteIcon />
				</button>
			</div>
			<CustomDropdown isOpen={isPopoverOpen} toggleIsOpen={toggleIsPopoverOpen}>
				<div css={propertySelectContainerStyle}>
					{propertySettings.children.map((propertyChildren) => (
						<button
							key={propertyChildren.id}
							value={propertyChildren.id}
							onClick={() => {
								onContextPropertySelect(
									propertyChildren.id,
									selectedProperty.id
								)
							}}
							css={propertySelectStyle}
						>
							{propertyChildren.title}
						</button>
					))}
				</div>
			</CustomDropdown>
			{selectedProperty?.children?.map((selectedPropertyChild) => {
				const propertySettings = getObjectById(
					selectedPropertyChild.id,
					screeningProperties
				)

				if (!propertySettings) {
					return null
				}

				return (
					<ScreeningPropertyFields
						key={IdGenerator()}
						propertySettings={propertySettings}
						screeningProperties={screeningProperties}
						selectedProperty={selectedPropertyChild}
						onContextPropertySelect={onContextPropertySelect}
						onContextPropertyValueUpdate={onContextPropertyValueUpdate}
						onContextPropertyDelete={onContextPropertyDelete}
					/>
				)
			})}
		</div>
	) : (
		<ScreeningPropertyWithSelect
			selectedProperty={selectedProperty}
			propertySettings={propertySettings}
			onContextPropertyValueUpdate={onContextPropertyValueUpdate}
			onContextPropertyDelete={onContextPropertyDelete}
		/>
	)
}
const ScreeningPropertyWithSelect: React.FC<ScreeningProperyWithSelectProps> = ({
	selectedProperty,
	propertySettings,
	onContextPropertyValueUpdate,
	onContextPropertyDelete,
}) => {
	const valuesArray = transformValuesToArray(
		propertySettings.valueType,
		propertySettings.valueRange
	)

	return (
		<div
			css={css`
				display: flex;
				flex-direction: column;
				border: 1px solid #c0c0c0;
				border-radius: 5px;
				padding: 1rem;
				margin-top: 0.5rem;
			`}
		>
			<div
				css={css`
					display: flex;
					align-items: center;
					justify-content: space-between;
				`}
			>
				<label
					css={css`
						margin: 0 0 0.25rem;
						font-size: 0.9rem;
						margin-right: 0.5rem;
					`}
				>
					{propertySettings.title}
				</label>
				<div>
					<button
						type='button'
						css={css`
							background-color: transparent;
							border: 0;
							cursor: pointer;
						`}
						onClick={() => {
							onContextPropertyDelete(selectedProperty.id)
						}}
					>
						<DeleteIcon />
					</button>
				</div>
			</div>
			<select
				value={selectedProperty.value}
				onChange={(event) => {
					onContextPropertyValueUpdate(
						propertySettings.id,
						event.currentTarget.value
					)
				}}
			>
				{valuesArray.map((value) => (
					<option key={IdGenerator()} value={value}>
						{value}
					</option>
				))}
			</select>
		</div>
	)
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
	children,
	isOpen,
	toggleIsOpen,
}) => {
	const wrapperRef = React.useRef<HTMLDivElement>(null)
	const buttonRef = React.useRef<HTMLButtonElement>(null)
	const [buttonHeight, setButtonHeight] = React.useState(0)

	const closeDropdown = () => {
		toggleIsOpen(false)
	}

	useDetectOutsideClick(wrapperRef, closeDropdown)

	React.useLayoutEffect(() => {
		if (buttonRef.current) {
			setButtonHeight(buttonRef.current.getBoundingClientRect().height ?? 0)
		}
	}, [])

	return (
		<div
			css={[
				css`
					position: relative;
					display: flex;
					flex-drection: column;
				`,
			]}
		>
			<button
				css={css`
					padding: 0.5rem;
					border: 1px solid #c0c0c0;
					background-color: transparent;
					border-radius: 0;
					transition: border-radius 150ms, background-color 150ms;

					@media (hover: hover) {
						&:hover {
							background-color: #e9e9ed;
							cursor: pointer;
							border-radius: 10px;
						}
					}
				`}
				ref={buttonRef}
				onClick={() => {
					toggleIsOpen((oldState) => {
						return !oldState
					})
				}}
			>
				Select property to add
			</button>
			{isOpen ? (
				<div
					ref={wrapperRef}
					css={css`
						z-index: 200;
						position: absolute;
						left: 0;
						top: ${buttonHeight}px;
					`}
				>
					{children}
				</div>
			) : null}
		</div>
	)
}

export default ScreeningProperties

type ScreeningPropertiesProps = {
	screeningProperties?: ScreeningPropertySettingsType[]
	choiceIndex: number
	parentChoiceIndex?: number
	questionDispatch: React.Dispatch<QuestionActionType>
	selectedProperties: ScreeningPropertyType[]
}

type ScreeningPropertyFieldsProps = {
	screeningProperties: ScreeningPropertySettingsType[]
	propertySettings: ScreeningPropertySettingsType
	selectedProperty: ScreeningPropertyType
	onContextPropertySelect: (
		selectedPropertyId: string,
		selectedPropertyParentId?: string | undefined
	) => void
	onContextPropertyValueUpdate: (id: string, value: string) => void
	onContextPropertyDelete: (selectedPropertyId: string) => void
}

type ScreeningProperyWithSelectProps = {
	selectedProperty: ScreeningPropertyType
	propertySettings: ScreeningPropertySettingsType
	onContextPropertyValueUpdate: (id: string, value: string) => void
	onContextPropertyDelete: (selectedPropertyId: string) => void
}

type CustomDropdownProps = {
	children: React.ReactNode
	isOpen: boolean
	toggleIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const propertySelectStyle = css`
	background-color: transparent;
	border: none;
	text-align: left;
	padding: 0.5rem 1rem;

	@media (hover: hover) {
		&:hover {
			cursor: pointer;
			background-color: #f5f5f5;
		}
	}
`

const propertySelectContainerStyle = css`
	display: flex;
	flex-direction: column;
	overflow: hidden;

	background-color: #ffffff;
	border: 1px solid #c0c0c0;
	border-radius: 5px;
`
