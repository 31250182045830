import { Row } from "react-table";

export const includeExcludeSearch = (
	rows: Row[],
	id: string[],
	filterValue: string
) => {
	const searchArray = filterValue.split(' ')

	const sortSearch = searchArray.reduce(
		(
			sortSearch: { tagsToInclude: string[]; tagsToExclude: string[] },
			searchWord
		) => {
			if (Array.from(searchWord)[0] === '!') {
				const excludeTag = Array.from(searchWord)
					.slice(1)
					.join('')
					.toLowerCase()

				if (excludeTag) {
					sortSearch.tagsToExclude.push(excludeTag)
				}
			} else if (searchWord) {
				sortSearch.tagsToInclude.push(searchWord.toLowerCase())
			}

			return sortSearch
		},
		{ tagsToInclude: [], tagsToExclude: [] }
	)

	const filteredRows = rows.reduce((filteredRows: Row[], row) => {
		const rowValues = row.values[id[0]].toLowerCase().split(' ')

		let allIncludedTagsIncluded = !sortSearch.tagsToInclude.length
		let allExcludedTagsExcluded = !sortSearch.tagsToExclude.length

		if (sortSearch.tagsToInclude.length) {
			allIncludedTagsIncluded = sortSearch.tagsToInclude.reduce(
				(matchesSearch: boolean, tagToInclude) => {
					const containsTag = rowValues.find((rowValue: string) =>
						rowValue.includes(tagToInclude)
					)

					if (!containsTag) {
						matchesSearch = false
					}

					return matchesSearch
				},
				true
			)
		}

		if (sortSearch.tagsToExclude.length) {
			allExcludedTagsExcluded = sortSearch.tagsToExclude.reduce(
				(matchesSearch: boolean, tagToInclude) => {
					const containsTag = rowValues.find((rowValue: string) =>
						rowValue.includes(tagToInclude)
					)

					if (containsTag) {
						matchesSearch = false
					}

					return matchesSearch
				},
				true
			)
		}

		if (allIncludedTagsIncluded && allExcludedTagsExcluded) {
			filteredRows.push(row)
		}

		return filteredRows
	}, [])

	return filteredRows
}