import { NEW_ID } from '../../constants'

import { getObjectById, getObjectByIdWithParent } from '../../utils/getObject'
import { getUpdatedTree } from '../../utils/getUpdatedTree'
import { transformValuesToArray } from '../../utils/transformValuesToArray'

import {
	BasicQuestionType,
	ChoiceType,
	ChoicesQuestionType,
	QuestionActionType,
	SCREENING_PROPERTY_VALUE_TYPE,
	ScreeningPropertyType,
	SurveyStartType,
	SurveySubmitType,
	isAddScreeningPropertyPayloadType,
	isBasicQuestionType,
	isChoicesQuestionType,
	isDeleteScrreningPropertyPayloadType,
	isUpdateScreeningPropertyValuePayloadType,
	isUpdateChoicesQuestionPayload,
	isUpdateBasicQuestionPayload,
	isNewChoicePayload,
	isDeleteChoicePropertiesPayload,
	isChoicePositionPayload,
	isUpdateChoicePayload,
	isDeleteChoicePayload,
	isAddChoicePropertiesPayload,
} from '../../types'

export const questionReducer = (
	draftState:
		| SurveyStartType
		| ChoicesQuestionType
		| BasicQuestionType
		| SurveySubmitType
		| undefined,
	action: QuestionActionType
):
	| SurveyStartType
	| ChoicesQuestionType
	| BasicQuestionType
	| SurveySubmitType
	| undefined => {
	if (!draftState) return
	if (action.type === 'updateQuestion') {
		if (
			isUpdateChoicesQuestionPayload(action.payload) ||
			isUpdateBasicQuestionPayload(action.payload)
		) {
			Object.assign(draftState, { ...action.payload })
			return draftState
		}
	} else if (action.type === 'updateQuestionType') {
		if (typeof action.payload !== 'string') return

		const newQuestionVariant = action.payload

		if (newQuestionVariant === 'basic') {
			const newQuestion: BasicQuestionType = {
				id: draftState.id,
				changed: true,
				order: draftState.order,
				visible: draftState.visible,
				headerTextKey: draftState.headerTextKey,
				nicknameTextKey: draftState.nicknameTextKey,
				ingressTextKey: draftState.ingressTextKey,
				choicesVariant: 'basic',
				showWeightQuestion: true,
				showHeightQuestion: true,
				showBirthdayQuestion: true,
				showGenderQuestion: true,
				calculateBmi: false,
				bmiTagRanges: [],
				showOnlyYear: false,
			}

			return newQuestion
		} else {
			if (isChoicesQuestionType(draftState)) {
				draftState.choicesVariant = newQuestionVariant
			} else if (isBasicQuestionType(draftState)) {
				const newQuestion: ChoicesQuestionType = {
					id: draftState.id,
					changed: true,
					order: draftState.order,
					visible: draftState.visible,
					headerTextKey: draftState.headerTextKey,
					nicknameTextKey: draftState.nicknameTextKey,
					ingressTextKey: draftState.ingressTextKey,
					alignment: 'vertical',
					choicesVariant: newQuestionVariant,
					choices: [],
				}

				return newQuestion
			}
		}
	} else if (action.type === 'addChoice' && isChoicesQuestionType(draftState)) {
		if (isNewChoicePayload(action.payload)) {
			const { parentChoiceIndex, ...rest } = action.payload

			const newChoice: ChoiceType = {
				id: NEW_ID,
				order: 0,
				offset: 0,
				properties: {
					screening: [],
					recommendation: [],
				},
				tags: '',
				imageId: 0,
				nicknameTextKey: '',
				subQuestionHeaderTextKey: '',
				subQuestionNicknameTextKey: '',
				subQuestionIngressTextKey: '',
				...rest,
			}

			if (typeof parentChoiceIndex !== 'number') {
				newChoice.order =
					draftState.choices.length !== 0
						? draftState.choices[draftState.choices.length - 1].order + 1
						: 1
				newChoice.choicesVariant = 'radio'

				if (draftState.choices.length) draftState.choices.push(newChoice)
				else draftState.choices = [newChoice]
			} else {
				const parentChoice = draftState.choices[parentChoiceIndex]

				if (typeof parentChoice.choices === 'undefined') {
					newChoice.order = 1
					parentChoice.choices = [newChoice]
				} else {
					newChoice.order = parentChoice.choices.length + 1
					parentChoice.choices.push(newChoice)
				}
			}
		}
	} else if (
		action.type === 'addScreeningProperty' &&
		isAddScreeningPropertyPayloadType(action.payload)
	) {
		const {
			parentChoiceIndex,
			choiceIndex,
			selectedPropertyId,
			selectedPropertyParentId,
			propertySettings,
		} = action.payload
		let choiceToUpdate: ChoiceType | undefined = undefined
		if (isChoicesQuestionType(draftState)) {
			if (typeof parentChoiceIndex !== 'undefined' && parentChoiceIndex > -1) {
				choiceToUpdate =
					draftState.choices?.[parentChoiceIndex].choices?.[choiceIndex]
			} else {
				choiceToUpdate = draftState.choices?.[choiceIndex]
			}

			if (choiceToUpdate) {
				if (selectedPropertyParentId) {
					const existingParent = getObjectById(
						selectedPropertyParentId,
						choiceToUpdate.properties.screening
					)

					if (existingParent) {
						const childIndex = existingParent.children?.findIndex((child) => {
							return child.id === selectedPropertyId
						})

						if (typeof childIndex === 'number' && childIndex >= 0) {
							return
						}

						const startValue = transformValuesToArray(
							propertySettings.valueType,
							propertySettings.valueRange
						)

						if (propertySettings) {
							const isParent =
								propertySettings.valueType ===
								SCREENING_PROPERTY_VALUE_TYPE.none

							const initialValue = startValue.length ? startValue[0] : ''

							const newParent = {
								...existingParent,
								children: [
									...(existingParent.children || []),
									{
										id: selectedPropertyId,
										title: propertySettings.title,
										...(isParent
											? {
													children: [],
											  }
											: { value: initialValue }),
									},
								],
							}

							choiceToUpdate.properties.screening = getUpdatedTree<
								ScreeningPropertyType
							>(newParent, choiceToUpdate.properties.screening)

							return
						}
					}
				}

				const propertyExists = getObjectById(
					selectedPropertyId,
					choiceToUpdate.properties.screening
				)

				if (propertyExists) {
					return
				}

				const startValue = transformValuesToArray(
					propertySettings.valueType,
					propertySettings.valueRange
				)

				if (propertySettings) {
					const isParent =
						propertySettings.valueType === SCREENING_PROPERTY_VALUE_TYPE.none

					const initialValue = startValue.length ? startValue[0] : ''

					choiceToUpdate.properties.screening = [
						...choiceToUpdate.properties.screening,
						{
							id: selectedPropertyId,
							title: propertySettings.title,
							...(isParent
								? {
										children: [],
								  }
								: { value: initialValue }),
						},
					]
				}
			}
		}
	} else if (
		action.type === 'updateScreeningPropertyValue' &&
		isUpdateScreeningPropertyValuePayloadType(action.payload)
	) {
		if (isChoicesQuestionType(draftState)) {
			const {
				parentChoiceIndex,
				choiceIndex,
				selectedPropertyId,
				value,
			} = action.payload
			let choiceToUpdate: ChoiceType | undefined = undefined

			if (typeof parentChoiceIndex !== 'undefined' && parentChoiceIndex > -1) {
				choiceToUpdate =
					draftState.choices?.[parentChoiceIndex].choices?.[choiceIndex]
			} else {
				choiceToUpdate = draftState.choices?.[choiceIndex]
			}

			if (!choiceToUpdate) return

			const propertyToUpdate = getObjectById(
				selectedPropertyId,
				choiceToUpdate.properties.screening
			)

			if (propertyToUpdate) {
				const updatedProperty = {
					...propertyToUpdate,
					value,
				}

				choiceToUpdate.properties.screening = getUpdatedTree(
					updatedProperty,
					choiceToUpdate.properties.screening
				)
			}
		}
	} else if (
		action.type === 'deleteScreeningProperty' &&
		isDeleteScrreningPropertyPayloadType(action.payload)
	) {
		if (isChoicesQuestionType(draftState)) {
			const {
				parentChoiceIndex,
				choiceIndex,
				selectedPropertyId,
			} = action.payload
			let choiceToUpdate: ChoiceType | undefined = undefined

			if (typeof parentChoiceIndex !== 'undefined' && parentChoiceIndex > -1) {
				choiceToUpdate =
					draftState.choices?.[parentChoiceIndex].choices?.[choiceIndex]
			} else {
				choiceToUpdate = draftState.choices?.[choiceIndex]
			}

			if (!choiceToUpdate) return

			const { parentObject } = getObjectByIdWithParent(
				selectedPropertyId,
				choiceToUpdate.properties.screening
			)

			if (parentObject) {
				const newParentNode = {
					...parentObject,
					children: parentObject.children?.filter(
						(child) => child.id !== selectedPropertyId
					),
				}

				choiceToUpdate.properties.screening = getUpdatedTree(
					newParentNode,
					choiceToUpdate.properties.screening
				)

				return
			}

			const indexToDelete = choiceToUpdate.properties.screening.findIndex(
				(property) => property.id === selectedPropertyId
			)
			if (indexToDelete >= 0) {
				choiceToUpdate.properties.screening.splice(indexToDelete, 1)
			}
		}
	} else if (action.type === 'addChoiceProperties') {
		if (
			isAddChoicePropertiesPayload(action.payload) &&
			isChoicesQuestionType(draftState)
		) {
			const {
				parentChoiceIndex,
				choiceIndex,
				...newProperties
			} = action.payload
			let choiceToUpdate: ChoiceType | undefined = undefined

			if (typeof parentChoiceIndex !== 'undefined' && parentChoiceIndex > -1) {
				choiceToUpdate =
					draftState.choices?.[parentChoiceIndex].choices?.[choiceIndex]
			} else {
				choiceToUpdate = draftState.choices?.[choiceIndex]
			}

			if (!choiceToUpdate) return

			choiceToUpdate.properties.recommendation.push(newProperties)
		}
	} else if (action.type === 'deleteChoiceProperties') {
		if (
			isDeleteChoicePropertiesPayload(action.payload) &&
			isChoicesQuestionType(draftState)
		) {
			const { parentChoiceIndex, choiceIndex } = action.payload

			let choiceToUpdate: ChoiceType | undefined = undefined

			if (typeof parentChoiceIndex === 'number' && parentChoiceIndex > -1) {
				choiceToUpdate =
					draftState.choices?.[parentChoiceIndex]?.choices?.[choiceIndex]
			} else choiceToUpdate = draftState.choices?.[choiceIndex]

			if (choiceToUpdate) choiceToUpdate.properties.recommendation = []
		}
	} else if (
		action.type === 'updateChoicePosition' &&
		isChoicesQuestionType(draftState)
	) {
		if (!isChoicePositionPayload(action.payload)) return

		const { parentChoiceIndex, oldIndex, newIndex } = action.payload

		let choicesToUpdate: Array<ChoiceType> | undefined = undefined
		let choiceToUpdate: ChoiceType | undefined = undefined

		if (typeof parentChoiceIndex === 'number' && parentChoiceIndex >= 0) {
			choicesToUpdate = draftState.choices[parentChoiceIndex].choices
		} else {
			choicesToUpdate = draftState.choices
		}

		if (choicesToUpdate) {
			choiceToUpdate = choicesToUpdate?.splice(oldIndex, 1)[0]
			choicesToUpdate?.splice(newIndex, 0, choiceToUpdate)
			choicesToUpdate?.forEach(
				(choice, choiceIndex) => (choice.order = choiceIndex + 1)
			)
		}
	} else if (
		action.type === 'updateChoice' &&
		isChoicesQuestionType(draftState)
	) {
		if (!isUpdateChoicePayload(action.payload)) return

		const { parentChoiceIndex, choiceIndex, ...rest } = action.payload

		let choicesToUpdate: Array<ChoiceType> | undefined = undefined
		let choiceToUpdate: ChoiceType | undefined = undefined

		if (typeof parentChoiceIndex === 'number' && parentChoiceIndex >= 0) {
			choicesToUpdate = draftState.choices[parentChoiceIndex].choices
		} else {
			choicesToUpdate = draftState.choices
		}

		choiceToUpdate = choicesToUpdate?.[choiceIndex]

		if (choiceToUpdate) Object.assign(choiceToUpdate, { ...rest })
	} else if (
		action.type === 'deleteChoice' &&
		isChoicesQuestionType(draftState)
	) {
		if (!isDeleteChoicePayload(action.payload)) return

		const { parentChoiceIndex, choiceIndex } = action.payload

		if (typeof parentChoiceIndex === 'number' && parentChoiceIndex >= 0) {
			draftState.choices[parentChoiceIndex].choices?.splice(choiceIndex, 1)
			draftState.choices[parentChoiceIndex].choices?.forEach(
				(choice, index) => (choice.order = index + 1)
			)
		} else {
			draftState.choices.splice(choiceIndex, 1)
			draftState.choices.forEach((choice, index) => (choice.order = index + 1))
		}
	}
}

export default questionReducer
