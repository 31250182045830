import * as React from 'react'
import { css, useTheme } from 'styled-components/macro'
import Color from 'color'

import { CssFunction, StyleVariants } from '../types/styled'

export enum ButtonVariant {
	primary = 'primary',
	secondary = 'secondary',
	success = 'success',
	danger = 'danger',
	icon = 'icon',
}

const Button: React.FC<ButtonProps> = ({
	variant,
	buttonCss,
	type = 'button',
	...props
}) => {
	const theme = useTheme()

	function getHoverColor(color: string) {
		const colorObject = Color(color)

		if (colorObject.luminosity() <= 0.25) {
			return colorObject.lighten(0.2)
		} else if (colorObject.luminosity() < 0.5) {
			return colorObject.lighten(0.1)
		} else {
			return colorObject.darken(0.075)
		}
	}

	const primaryButtonHoverColor = getHoverColor(theme.secondaryColor)
	const primaryButtonTextHoverColor = primaryButtonHoverColor.isDark()
		? '#ffffff'
		: 'rgba(0,0,0,0.87)'
	const disabledTextColor = 'rgba( 17,17,17,0.6 )'

	const variantStyles: StyleVariants<ButtonVariant> = {
		[ButtonVariant.primary]: css`
			border-color: transparent;
			background-color: ${({ theme }) => theme.secondaryColor};
			color: ${({ theme }) => theme.textColorForSecondary};

			&:disabled {
				background-color: #c0c0c0;
				color: ${disabledTextColor};
			}

			&:not(:disabled) {
				&:hover {
					background-color: ${primaryButtonHoverColor.toString()};
					color: ${primaryButtonTextHoverColor};
				}
			}
		`,
		[ButtonVariant.secondary]: css`
			border: 2px solid ${({ theme }) => theme.secondaryColor};
			color: rgba(0, 0, 0, 0.86);
			background-color: transparent;
			cursor: default;

			&:disabled {
				border-color: #c0c0c0;
				color: ${disabledTextColor};
			}

			&:not(:disabled) {
				&:hover {
					border-color: ${primaryButtonHoverColor.toString()};
				}
			}
		`,
		[ButtonVariant.success]: css`
			color: #ffffff;
			border-color: transparent;
			background-color: #28a745;
			&:disabled {
        opacity: 0.4;
			}

			&:not(:disabled) {
				&:hover {
					background-color: ${Color('#28a745').lighten(0.1).toString()};
				}
			}
		`,
		[ButtonVariant.danger]: css`
			color: #ffffff;
			background-color: #cd2e00;
			border-color: transparent;
			&:disabled {
        opacity: 0.4;
			}

			&:not(:disabled) {
				&:hover {
					background-color: ${Color('#cd2e00').lighten(0.1).toString()};
				}
			}
		`,
		[ButtonVariant.icon]: css`
			font-size: 100%;
			background-color: transparent;
			font-family: inherit;
			border: 0;
			padding: 0.5rem;
			display: flex;
			align-items: center;
		`,
	}
	return (
		<button
			css={[
				css`
					border-radius: 7px;
					border: 2px solid transparent;
					padding: 1rem 2rem;
					font-weight: 600;
					font-size: 1.125rem;
					outline-offset: 5px;

					&:not(:disabled) {
						&:hover {
							cursor: pointer;
						}

						&:active {
							transform: scale(0.98);
						}
					}
				`,
				variant && variantStyles[variant],
				buttonCss,
			]}
			type={type}
			{...props}
		>
			{props.children}
		</button>
	)
}

export default Button

type ReactButton = React.DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
>

type ButtonProps = ReactButton & {
	variant?: ButtonVariant
	buttonCss?: CssFunction
	css?: never
}
