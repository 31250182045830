import React from 'react'
import { css } from 'styled-components/macro'

import { useEditorState } from '../context/EditorProvider'

const Topbar: React.FC = ({ children }) => {
	const { surveyDefinition } = useEditorState()

	return (
		<div
			css={css`
				position: sticky;
				display: flex;
				align-items: center;
				top: 0rem;
				min-height: 1rem;
				background-color: #34373b;
				color: white;
				padding: 0 2rem;
				z-index: 99999;
			`}
		>
			<div
				css={css`
					flex-grow: 1;
					display: flex;
					align-items: center;
					gap: 1rem;
				`}
			>
				<h1>Survey Editor</h1>
				{surveyDefinition &&
				typeof surveyDefinition.sourceOrgId === 'number' &&
				typeof surveyDefinition.sourceDeptId === 'number' ? (
					<div
						css={css`
							display: flex;
							flex-direction: column;
						`}
					>
						<h4
							css={css`
								margin-bottom: 0;
								margin-top: 0;
							`}
						>
							Loaded Survey:
						</h4>
						<small>Organization id: {surveyDefinition.sourceOrgId}</small>
						<small>Department id: {surveyDefinition.sourceDeptId}</small>
					</div>
				) : null}
			</div>
			{children && (
				<div
					css={css`
						display: flex;
						gap: 1rem;
					`}
				>
					{children}
				</div>
			)}
		</div>
	)
}

export default Topbar
