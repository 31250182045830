import * as React from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { css } from 'styled-components/macro'
import { useFetch, CachePolicies } from 'use-http'
import { FilterProps } from 'react-table'

import { useEditorState } from '../../context/EditorProvider'

import { getObjectByIdWithParent } from '../../utils/getObject'

import Button, { ButtonVariant } from '../../components/Button'
import Spinner from '../../components/Spinner'
import Alert, { AlertVariant } from '../../components/Alert'
import Topbar from '../../components/Topbar'
import Container from '../../components/Container'

import {
	sortTagsBySkips,
	getSurveyPaths,
	getApproxNumberOfEndpoints,
	matchTagCombinationsWithRules,
} from './utils'

import VisualizedQuestionPaths from './VisualizedQuestionPaths'
import Table from './Table'

import {
	ChoicesQuestionType,
	isChoicesQuestionType,
	SurveyDefinitionQuestionType,
	BasicQuestionType,
	TranslationObjectType,
	isBasicQuestionType,
	TagRuleType,
	SurveySubmitType,
	SurveyQueryParams,
	isValidBasicQuestionType,
	isValidChoicesQuestionType,
	isValidVisualQuestionType,
	TagPreviewColumnsType,
} from '../../types'

const TagPreview = (): React.ReactElement => {
	const history = useHistory()
	const { search } = useLocation()
	const { surveyDefinition, translations } = useEditorState()
	const { orgToRequest, deptToRequest } = useParams<SurveyQueryParams>()
	const [workoutLanguages, setWorkoutLanguages] = React.useState<string[]>([
		'en-US',
	])
	const possibleFilterLanguages = {
		'en-US': 'English',
		'nb-NO': 'Norwegian',
		'sv-SE': 'Swedish',
		'da-DK': 'Danish',
		'fi-FI': 'Finnish',
	}

	const [filterSnapshot, setFilterSnapshot] = React.useState<FilterType>({
		workoutLanguagesSnapshot: [],
		surveyPathsSnapshot: [],
		showAllTagsSnapshot: false,
	})

	const firstValidQuestion = surveyDefinition?.questions.find(
		(question) =>
			isValidChoicesQuestionType(question) || isValidBasicQuestionType(question)
	)

	const { request: workoutRequest } = useFetch(
		`${process.env.REACT_APP_EXORLIVE_API_URL}/osloapi/survey/programs?organizationId=${orgToRequest}`,
		{
			credentials: 'include',
			cachePolicy: CachePolicies.NO_CACHE,
		},
		[]
	)

	const { request: planRequest } = useFetch(
		`${process.env.REACT_APP_EXORLIVE_API_URL}/osloapi/survey/plans?organizationId=${orgToRequest}`,
		{
			credentials: 'include',
			cachePolicy: CachePolicies.NO_CACHE,
		},
		[]
	)

	const [showAllTags, toggleShowAllTags] = React.useState<boolean>(false)
	const [selectedChoices, setSelectedChoices] = React.useState<
		{ questionIndex: number; questionId: string; choiceId: string }[]
	>([])

	if (workoutRequest.loading || planRequest.loading)
		return (
			<React.Fragment>
				<Topbar />
				<Spinner />
			</React.Fragment>
		)

	const workoutPrograms = Array.isArray(workoutRequest.data)
		? (workoutRequest.data as WorkoutProgramType[])
		: undefined

	const plans = Array.isArray(planRequest.data)
		? (planRequest.data as WorkoutProgramType[])
		: undefined

	if (!firstValidQuestion || !surveyDefinition || !translations) {
		return (
			<React.Fragment>
				<Topbar>
					<Button
						type='button'
						onClick={() => {
							window.scrollTo(0, 0)

							history.replace({
								pathname: `/editor/${orgToRequest}/${deptToRequest}`,
								search: search,
							})
						}}
					>
						Back
					</Button>
				</Topbar>
				<Container>
					{!surveyDefinition || !translations ? (
						<Alert>
							<p>Error loading survey or translations. Please try again.</p>
						</Alert>
					) : !firstValidQuestion ? (
						<Alert variant={AlertVariant.warning}>
							<h3>No valid tag questions exist for this survey.</h3>
							<p>
								Valid tag preview questions consist of atleast one choice and is
								of type "single choice" or "slider".
							</p>
						</Alert>
					) : null}
				</Container>
			</React.Fragment>
		)
	}

	const containsChangedQuestions = !!surveyDefinition.questions.find(
		(question) => question.changed
	)
	const englishTranslations = translations['en-US']
	const questions = surveyDefinition.questions

	const validVisualQuestions = questions.filter(isValidVisualQuestionType)

	const lastQuestionAnswered = selectedChoices[selectedChoices.length - 1]
	const nextQuestion = validVisualQuestions.reduce(
		(nextQuestion: NextQuestionType | undefined, question, questionIndex) => {
			if (
				lastQuestionAnswered &&
				question.id === lastQuestionAnswered.questionId
			) {
				if (isChoicesQuestionType(question)) {
					question.choices.forEach((choice) => {
						if (choice.id === lastQuestionAnswered.choiceId) {
							const potentialNextQuestion =
								validVisualQuestions[questionIndex + 1 + choice.offset]

							if (potentialNextQuestion) {
								nextQuestion = {
									index: questionIndex + 1 + choice.offset,
									definition: potentialNextQuestion,
								}
							} else {
								nextQuestion = {
									index: questionIndex + 1,
									definition: validVisualQuestions[questionIndex + 1],
								}
							}
						}
						if (choice.choices) {
							choice.choices.forEach((subChoice) => {
								if (subChoice.id === lastQuestionAnswered.choiceId) {
									const potentialNextQuestion =
										validVisualQuestions[questionIndex + 1 + subChoice.offset]

									if (potentialNextQuestion) {
										nextQuestion = {
											index: questionIndex + 1 + subChoice.offset,
											definition: potentialNextQuestion,
										}
									} else {
										nextQuestion = {
											index: questionIndex + 1,
											definition: validVisualQuestions[questionIndex + 1],
										}
									}
								}
							})
						}
					})
				} else if (isBasicQuestionType(question)) {
					nextQuestion = {
						index: questionIndex + 1,
						definition: validVisualQuestions[questionIndex + 1],
					}
				}
			}

			return nextQuestion
		},
		undefined
	)

	const tagsSortedBySkips = sortTagsBySkips(validVisualQuestions)

	let surveyPaths: string[][][] = []

	if (selectedChoices.length) {
		// Add parent choice tag
		const selectedChoicesToTags = selectedChoices.reduce(
			(selectedChoicesToTags: string[][], selectedChoice) => {
				const selectedQuestion = validVisualQuestions.find(
					(validQuestion) => validQuestion.id === selectedChoice.questionId
				)

				if (selectedQuestion) {
					if (
						isChoicesQuestionType(selectedQuestion) &&
						selectedQuestion.choices
					) {
						const { parentObject: parentChoice, object: choice } = getObjectByIdWithParent(
							selectedChoice.choiceId,
							selectedQuestion.choices
						)

						if (choice) {
							const choiceTags = [
								`${parentChoice?.tags ? parentChoice.tags : ''}${
									parentChoice?.tags && choice.tags ? ' ' : ''
								}${choice.tags}`,
							]

							selectedChoicesToTags.push(choiceTags)
						}
					} else if (isBasicQuestionType(selectedQuestion)) {
						const selectedBmiTagRange = selectedQuestion.bmiTagRanges.find(
							(bmiTagRange) => bmiTagRange.id === selectedChoice.choiceId
						)

						selectedBmiTagRange &&
							selectedChoicesToTags.push([selectedBmiTagRange.bmiTag])
					}
				}

				return selectedChoicesToTags
			},
			[]
		)

		surveyPaths = getSurveyPaths(
			tagsSortedBySkips.slice(
				nextQuestion ? nextQuestion.index : tagsSortedBySkips.length - 1
			),
			selectedChoicesToTags
		)
	} else {
		surveyPaths = getSurveyPaths(tagsSortedBySkips)
	}

	const numberOfUniqueEndpoints = getApproxNumberOfEndpoints(surveyPaths)
	const genereatedNumberOfUniqueEndPoints = getApproxNumberOfEndpoints(
		filterSnapshot.surveyPathsSnapshot
	)

	const onLanguageClick = (language: string) => {
		const newWorkoutLanguages = [...workoutLanguages]

		const existingLanugageIndex = workoutLanguages.findIndex(
			(workoutLanguage) => workoutLanguage === language
		)

		if (existingLanugageIndex > -1) {
			newWorkoutLanguages.splice(existingLanugageIndex, 1)
		} else {
			const languageIndex = Object.keys(possibleFilterLanguages).findIndex(
				(possibleFilterLanguage) => possibleFilterLanguage === language
			)

			newWorkoutLanguages.splice(languageIndex, 0, language)
		}

		setWorkoutLanguages(newWorkoutLanguages)
	}

	const onButtonBackClick = () => {
		window.scrollTo(0, 0)

		history.replace({
			pathname: `/editor/${orgToRequest}/${deptToRequest}`,
			search: search,
		})
	}

	// If we don't receive an Array from the server then the user is either not logged in, or lacking the proper permissions.
	const isUserLoggedIn = Array.isArray(workoutPrograms)

	return (
		<React.Fragment>
			<Topbar>
				<Button type='button' onClick={onButtonBackClick}>
					Back
				</Button>
			</Topbar>
			<div
				css={css`
					max-width: 2000px;
					display: flex;
					flex-direction: column;
					margin: 1rem auto 2rem;
					padding: 0 2rem;

					.visualContainer {
						margin: 1rem 0;
						height: 550px;
						width: 100%;
					}
				`}
			>
				{containsChangedQuestions ? (
					<Alert variant={AlertVariant.error}>
						<p>
							Survey contains altered or new questions. Save the survey to be
							certain that tag preview is accurate.
						</p>
					</Alert>
				) : null}
				{isUserLoggedIn && !workoutPrograms?.length ? (
					<Alert variant={AlertVariant.warning}>
						<p>No workouts found for this organization.</p>
					</Alert>
				) : null}
				{isUserLoggedIn && !plans?.length ? (
					<Alert variant={AlertVariant.warning}>
						<p>No plans found for this organization.</p>
					</Alert>
				) : null}
				{!isUserLoggedIn ? (
					<Alert variant={AlertVariant.warning}>
						<p>
							Log in to ExorLive to be able to view matching workout programs
						</p>
					</Alert>
				) : null}
				<div css={filterBoxStyle}>
					<h1
						css={css`
							margin-bottom: 0.5rem;
						`}
					>
						Filters
					</h1>
					<VisualizedQuestionPaths
						validVisualQuestions={validVisualQuestions}
						translations={englishTranslations}
						selectedChoicesContext={{
							nextQuestion,
							selectedChoices,
							setSelectedChoices,
						}}
					/>
					{isUserLoggedIn ? (
						<div
							css={css`
								margin-bottom: 1rem;
							`}
						>
							<p
								css={css`
									margin: 0 0 0.5rem;
								`}
							>
								Languages for workout programs & plans
							</p>
							<div
								css={css`
									display: flex;
									align-items: center;
									font-size: 0.9rem;

									> :not(:last-child) {
										margin-right: 0.5rem;
									}

									input {
										display: none;

										&:checked + label {
											background-color: #a80047;
											color: white;
										}
									}

									> label {
										color: #a80047;
										padding: 0.5rem;
										border: 2px solid #a80047;
										border-radius: 3px;
									}
								`}
							>
								{Object.entries(possibleFilterLanguages).map(
									([languageCode, language], languageIndex: number) => (
										<React.Fragment key={languageIndex}>
											<input
												type='checkbox'
												id={`${language}-workouts`}
												name='workout-languages'
												checked={workoutLanguages.includes(languageCode)}
												onChange={() => onLanguageClick(languageCode)}
											/>
											<label htmlFor={`${language}-workouts`}>{language}</label>
										</React.Fragment>
									)
								)}
							</div>
						</div>
					) : null}
					<div className='show-all-tag-combinations'>
						<input
							type='checkbox'
							id='showAllInput'
							checked={showAllTags}
							onChange={() => toggleShowAllTags(!showAllTags)}
							css={css`
								margin-right: 0.25rem;
							`}
						/>
						<label htmlFor='showAllInput'>Show all tag combinations</label>
					</div>
					<Button
						type='button'
						variant={ButtonVariant.success}
						buttonCss={css`
							margin-top: 1rem;
						`}
						onClick={() => {
							setFilterSnapshot({
								workoutLanguagesSnapshot: workoutLanguages,
								showAllTagsSnapshot: showAllTags,
								surveyPathsSnapshot: surveyPaths,
							})
						}}
					>
						Generate tags
					</Button>
					<small>
						Generates approximately{' '}
						{numberOfUniqueEndpoints.toLocaleString('nb-NO')} unprocessed tags.
					</small>
				</div>
				{filterSnapshot.surveyPathsSnapshot?.length ? (
					<TagTable
						tagRules={surveyDefinition.postProcessingRules.tagRules}
						filterSnapshot={filterSnapshot}
						questions={questions}
						englishTranslations={englishTranslations}
						workoutPrograms={workoutPrograms}
						plans={plans}
						totalNumberOfUniqueEndpoints={genereatedNumberOfUniqueEndPoints}
					/>
				) : null}
			</div>
		</React.Fragment>
	)
}

const TagTable = React.memo(
	({
		tagRules,
		filterSnapshot,
		workoutPrograms,
		plans,
		totalNumberOfUniqueEndpoints,
	}: TagTableProps) => {
		const {
			workoutLanguagesSnapshot: selectedLanguages,
			showAllTagsSnapshot: showAllTags,
			surveyPathsSnapshot: surveyPaths,
		} = filterSnapshot

		// If we don't receive an Array from the server then the user is either not logged in, or lacking the proper permissions.
		const isUserLoggedIn = Array.isArray(workoutPrograms)

		const uniqueTagCombinations = React.useMemo(() => {
			if (showAllTags) {
				return surveyPaths.reduce(
					(
						sum: {
							tagCombinations: string[]
							processedTagCombinations: string[]
						},
						surveyPath
					) => {
						const matchedTagCombinations = matchTagCombinationsWithRules(
							surveyPath,
							tagRules,
							showAllTags
						)

						sum.tagCombinations.push(...matchedTagCombinations.tagCombinations)
						sum.processedTagCombinations.push(
							...matchedTagCombinations.processedTagCombinations
						)

						return sum
					},
					{ tagCombinations: [], processedTagCombinations: [] }
				)
			} else {
				const result = surveyPaths.reduce(
					(
						sum: {
							tagCombinations: string[]
							processedTagCombinations: string[]
						},
						surveyPath
					) => {
						const matchedTagCombinations = matchTagCombinationsWithRules(
							surveyPath,
							tagRules,
							showAllTags
						)

						sum.tagCombinations.push(...matchedTagCombinations.tagCombinations)
						sum.processedTagCombinations.push(
							...matchedTagCombinations.processedTagCombinations
						)

						return sum
					},
					{ tagCombinations: [], processedTagCombinations: [] }
				)
				return {
					tagCombinations: Array.from(new Set(result.tagCombinations)),
					processedTagCombinations: Array.from(
						new Set(result.processedTagCombinations)
					),
				}
			}
		}, [showAllTags, surveyPaths, tagRules])

		const languageSortedWorkouts = React.useMemo(
			() =>
				Array.isArray(workoutPrograms)
					? workoutPrograms.reduce(
							(
								languageSortedWorkouts: { [key: string]: WorkoutProgramType[] },
								workoutProgram
							) => {
								const workoutLanguage = workoutProgram.Culture

								if (
									!selectedLanguages?.includes(workoutLanguage) ||
									!workoutProgram.Tags.length
								)
									return languageSortedWorkouts

								if (!languageSortedWorkouts?.[workoutLanguage]) {
									languageSortedWorkouts[workoutLanguage] = []
								}

								languageSortedWorkouts[workoutLanguage].push(workoutProgram)

								return languageSortedWorkouts
							},
							{
								...selectedLanguages.reduce(
									(
										initVals: { [key: string]: WorkoutProgramType[] },
										selectedLanguage
									) => {
										initVals[selectedLanguage] = []

										return initVals
									},
									{}
								),
							}
					  )
					: {
							...selectedLanguages.reduce(
								(
									initVals: { [key: string]: WorkoutProgramType[] },
									selectedLanguage
								) => {
									initVals[selectedLanguage] = []

									return initVals
								},
								{}
							),
					  },
			[workoutPrograms, selectedLanguages]
		)

		const languageSortedPlans = React.useMemo(
			() =>
				Array.isArray(plans)
					? plans.reduce(
							(
								languageSortedPlans: { [key: string]: WorkoutProgramType[] },
								plan
							) => {
								const planLanguage = plan.Culture

								if (
									!selectedLanguages?.includes(planLanguage) ||
									!plan.Tags.length
								) {
									return languageSortedPlans
								}

								if (!languageSortedPlans?.[planLanguage]) {
									languageSortedPlans[planLanguage] = []
								}

								languageSortedPlans[planLanguage].push(plan)

								return languageSortedPlans
							},
							{
								...selectedLanguages.reduce(
									(
										initVals: { [key: string]: WorkoutProgramType[] },
										selectedLanguage
									) => {
										initVals[selectedLanguage] = []

										return initVals
									},
									{}
								),
							}
					  )
					: {
							...selectedLanguages.reduce(
								(
									initVals: { [key: string]: WorkoutProgramType[] },
									selectedLanguage
								) => {
									initVals[selectedLanguage] = []

									return initVals
								},
								{}
							),
					  },
			[plans, selectedLanguages]
		)

		const data = React.useMemo(
			() =>
				uniqueTagCombinations.processedTagCombinations.map(
					(processedTagCombination, uniqueTagCombinationIndex: number) => {
						const originalTagCombination = uniqueTagCombinations.tagCombinations[
							uniqueTagCombinationIndex
						].split(',')

						const processedTagCombinationArray = processedTagCombination.split(
							','
						)

						const matchingWorkoutData = Object.entries(
							languageSortedWorkouts
						).reduce(
							(
								tableWorkoutData: WorkoutDataForTableType,
								[languageCode, workouts]: [string, WorkoutProgramType[]]
							) => {
								const matchingWorkouts = workouts
									.reduce((matchingWorkouts: MatchType[], workout) => {
										const filteredTagCombinations = processedTagCombinationArray.filter(
											Boolean
										)

										if (!filteredTagCombinations.length) {
											return matchingWorkouts
										}

										const matchingProgram = filteredTagCombinations.reduce(
											(
												matchingProgram: { value: number; matches: boolean },
												tag
											) => {
												const lowerCasedWorkoutTags = workout.Tags.map(
													(workoutTag) => workoutTag.toLowerCase()
												)

												if (
													!lowerCasedWorkoutTags.includes(tag.toLowerCase())
												) {
													matchingProgram.matches = false
												}

												matchingProgram.value -=
													lowerCasedWorkoutTags.length -
													filteredTagCombinations.length

												return matchingProgram
											},
											{ value: 1000, matches: true }
										)

										if (matchingProgram.matches) {
											matchingWorkouts.push({
												id: workout.Id,
												guid: workout.Guid,
												type: 'workout',
												title: workout.Title,
												value: matchingProgram.value,
											})
										}

										return matchingWorkouts
									}, [])
									.sort((workoutA, workoutB) => {
										const difference = workoutB.value - workoutA.value

										if (difference === 0) {
											return workoutB.id - workoutA.id
										}

										return difference
									})

								if (matchingWorkouts.length) {
									tableWorkoutData[
										'workoutMatches_' + languageCode
									] = matchingWorkouts
									tableWorkoutData['numberOfMatches_' + languageCode] =
										matchingWorkouts.length
								} else {
									tableWorkoutData['workoutMatches_' + languageCode] =
										'No matches'
									tableWorkoutData['numberOfMatches_' + languageCode] = 0
								}

								return tableWorkoutData
							},
							{}
						)

						const matchingPlanData = Object.entries(languageSortedPlans).reduce(
							(
								matchingPlansData: WorkoutDataForTableType,
								[language, plans]: [string, WorkoutProgramType[]]
							) => {
								const matchingPlans = plans
									.reduce((matchingPlans: MatchType[], plan) => {
										const filteredTagCombinations = processedTagCombinationArray.filter(
											Boolean
										)

										if (!filteredTagCombinations.length) {
											return matchingPlans
										}

										const matchingPlan = filteredTagCombinations.reduce(
											(
												matchingPlan: { value: number; matches: boolean },
												tag
											) => {
												const lowerCasedPlanTags = plan.Tags.map((planTag) =>
													planTag.toLowerCase()
												)

												if (!lowerCasedPlanTags.includes(tag.toLowerCase())) {
													matchingPlan.matches = false
												}

												matchingPlan.value -=
													lowerCasedPlanTags.length -
													filteredTagCombinations.length

												return matchingPlan
											},
											{ value: 1000, matches: true }
										)

										if (matchingPlan.matches) {
											matchingPlans.push({
												id: plan.Id,
												guid: plan.Guid,
												type: 'plan',
												title: plan.Title,
												value: matchingPlan.value,
											})
										}

										return matchingPlans
									}, [])
									.sort((planA, planB) => {
										const difference = planB.value - planA.value

										if (difference === 0) {
											return planB.id - planA.id
										}

										return difference
									})

								if (matchingPlans.length) {
									matchingPlansData['planMatches_' + language] = matchingPlans
									matchingPlansData['numberOfPlanMatches_' + language] =
										matchingPlans.length
								} else {
									matchingPlansData['planMatches_' + language] = 'No matches'
									matchingPlansData['numberOfPlanMatches_' + language] = 0
								}

								return matchingPlansData
							},
							{}
						)

						const uniqueTagData: { [key: string]: unknown } = {
							index: uniqueTagCombinationIndex + 1,
							processedTagCombination: processedTagCombinationArray
								.filter(Boolean)
								.join(' '),
							unprocessedTagCombination: originalTagCombination
								.filter(Boolean)
								.join(' '),
							...matchingWorkoutData,
							...matchingPlanData,
						}

						return uniqueTagData
					}
				),
			[uniqueTagCombinations, languageSortedWorkouts, languageSortedPlans]
		)

		const columns = React.useMemo(
			() => [
				{
					Header: 'Number',
					accessor: 'index',
				},
				{
					Header: (
						<React.Fragment>
							Processed tags
							<br />
						</React.Fragment>
					),
					accessor: 'processedTagCombination',
					filter: 'includeExcludeSearch',
				},
				...(showAllTags
					? [
							{
								Header: (
									<React.Fragment>
										Unprocessed tags
										<br />
									</React.Fragment>
								),
								accessor: 'unprocessedTagCombination',
								filter: 'includeExcludeSearch',
							},
					  ]
					: []),
				...(isUserLoggedIn
					? selectedLanguages?.reduce(
							(matchingWorkouts: TagPreviewColumnsType, workoutLanguage) => {
								matchingWorkouts = [
									...matchingWorkouts,
									{
										Header: (
											<React.Fragment>
												{workoutLanguage}
												<br />
												Training programs
											</React.Fragment>
										),
										accessor: 'workoutMatches_' + workoutLanguage,
										filter: 'guidSearch',
									},
									{
										Header: (
											<React.Fragment>
												{workoutLanguage}
												<br /> no. of workouts
											</React.Fragment>
										),
										accessor: 'numberOfMatches_' + workoutLanguage,
										Filter: NumberRangeColumnFilter,
										filter: 'between',
									},
									{
										Header: (
											<React.Fragment>
												{workoutLanguage}
												<br />
												Plans
												<br />
											</React.Fragment>
										),
										accessor: 'planMatches_' + workoutLanguage,
										filter: 'guidSearch',
									},
									{
										Header: (
											<React.Fragment>
												{workoutLanguage}
												<br /> no. of plans <br />
											</React.Fragment>
										),
										accessor: 'numberOfPlanMatches_' + workoutLanguage,
										Filter: NumberRangeColumnFilter,
										filter: 'between',
									},
								]

								return matchingWorkouts
							},
							[]
					  )
					: []),
			],
			[selectedLanguages, isUserLoggedIn, showAllTags]
		)

		return (
			<Table
				columns={columns}
				data={data ? data : []}
				totalNumberOfUniqueEndpoints={totalNumberOfUniqueEndpoints}
				showAllTags={showAllTags}
				workoutLanguages={selectedLanguages}
			/>
		)
	}
)

const NumberRangeColumnFilter = ({
	column: { filterValue = [], preFilteredRows, setFilter, id },
}: FilterProps<Record<string, unknown>>) => {
	const [min, max] = React.useMemo(() => {
		let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
		let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0

		preFilteredRows.forEach((row) => {
			min = Math.min(row.values[id], min)
			max = Math.max(row.values[id], max)
		})

		return [min, max]
	}, [id, preFilteredRows])

	return (
		<div
			css={css`
				display: flex;
			`}
		>
			<input
				value={filterValue[0] >= 0 ? filterValue[0] : ''}
				type='number'
				onChange={(event) => {
					const userInput = parseInt(event.target.value, 10)

					setFilter((old: Array<string> = []) => [
						userInput >= 0 ? userInput : undefined,
						old[1],
					])
				}}
				placeholder={`Min (${min})`}
				css={css`
					width: 70px;
					margin-right: 0.5rem;
				`}
			/>
			to
			<input
				value={filterValue[1] >= 0 ? filterValue[1] : ''}
				type='number'
				onChange={(event) => {
					const userInput = parseInt(event.target.value, 10)

					setFilter((old: Array<string> = []) => [
						old[0],
						userInput >= 0 ? userInput : undefined,
					])
				}}
				placeholder={`Max (${max})`}
				css={css`
					width: 70px;
					margin-right: 0.5rem;
				`}
			/>
		</div>
	)
}

export default TagPreview

type FilterType = {
	workoutLanguagesSnapshot: string[]
	showAllTagsSnapshot: boolean
	surveyPathsSnapshot: string[][][]
}

type WorkoutDataForTableType = {
	[key: string]: string | number | { guid: string; title: string }[]
}

type WorkoutProgramType = {
	$id: string
	Id: number
	Guid: string
	Title: string
	Culture: string
	Tags: Array<string>
}

type TagTableProps = {
	tagRules: TagRuleType[]
	filterSnapshot: FilterType
	questions: Array<SurveyDefinitionQuestionType>
	englishTranslations: TranslationObjectType
	workoutPrograms: Array<WorkoutProgramType> | undefined
	plans: Array<WorkoutProgramType> | undefined
	totalNumberOfUniqueEndpoints: number
}

type MatchType = {
	id: number
	guid: string
	type: string
	title: string
	value: number
}

type NextQuestionType = {
	index: number
	definition: ChoicesQuestionType | BasicQuestionType | SurveySubmitType
}

const filterBoxStyle = css`
	display: flex;
	flex: 1;
	flex-direction: column;
	background-color: #eeee;
	border-radius: 1rem;
	align-items: flex-start;

	padding: 1rem;
	padding-top: 0;

	.questionContainer {
		display: flex;
		overflow-x: auto;
		width: 100%;
		white-space: nowrap;
		padding: 1rem 0;

		border: 2px solid #d2d2d2;
		border-radius: 5px;
	}

	.hide-unique {
		display: flex;
		align-items: center;
		margin: 0.75rem 0;
	}
`
